/*** IMPORTS FROM imports-loader ***/
(function() {

(function (root, factory) {
	if (typeof define === 'function' && define.amd) {
		define([ 'chartist' ], function (chartist) {
			return (root.returnExportsGlobal = factory(chartist));
		});
	}
	else if (typeof exports === 'object')
		module.exports = factory(require('chartist'));
	else
		root['Chartist.plugins.seriesSelection'] = factory(root.Chartist);
}(this, function (Chartist) {

	var defaultOptions = {};

	Chartist.plugins = Chartist.plugins || {};

	Chartist.plugins.seriesSelection = function (options) {
		options = Chartist.extend({}, defaultOptions, options);
		return function seriesSelection(chart) {
			var $chartContainer = chart.container;

			if (!$chartContainer) {
				return null
			}

			var
				$translate = options.translatePlugin,
				$seriesSelection = $chartContainer.querySelector('.chartist-series-selection'),
				uniqueId = _.uniqueId(),

				$dropdownWrapper = document.createElement("div"),
				$dropdownButton = document.createElement("button"),
				$dropdownButtonLabel = document.createElement("span"),
				$dropdownButtonValue = document.createElement("span"),
				$dropdownMenu = document.createElement("ul"),
				$caretIcon = document.createElement("i"),
				$dropdownItem
			;

			if (!$seriesSelection) {
				$seriesSelection = document.createElement('div');
				$seriesSelection.className = (!options.class) ? 'chartist-series-selection' : 'chartist-series-selection ' + options.class;
				$chartContainer.appendChild($seriesSelection);
			}
			else {
				$($seriesSelection).empty();
			}

			if (options.verticalSeries && options.verticalSeries.length > 0) {
				// -------------------- PIE CHART SERIES SELECTION ------------------
				//Dropdown Button
				$dropdownButton.className = "btn btn-default dropdown-toggle";
				$dropdownButton.id = "series-selection-dropdown" + uniqueId;
				$dropdownButton.setAttribute("data-toggle", "dropdown");
				$dropdownButtonLabel.className = "dropdown-label";
				$dropdownButtonLabel.innerText = $translate.instant("modules.visualization.chartist.period")
				$dropdownButtonValue.className = "selected-item";
				$dropdownButtonValue.innerText = options.selectedVerticalSeries ? options.selectedVerticalSeries : $translate.instant('All');
				$caretIcon.className = "fas fa-caret-down";
				$dropdownButton.appendChild($dropdownButtonLabel);
				$dropdownButton.appendChild($dropdownButtonValue);
				$dropdownButton.appendChild($caretIcon);

				//Dropdown Menu
				$dropdownMenu.className = "dropdown-menu";
				$dropdownMenu.setAttribute("aria-labelledby", "series-selection-dropdown" + uniqueId);

				//Dropdown Wrapper
				$dropdownWrapper.className = "chart-dropdown";
				$dropdownWrapper.appendChild($dropdownButton);
				$dropdownWrapper.appendChild($dropdownMenu);

				_.each(options.verticalSeries, function(seriesLabel) {
					$dropdownItem = document.createElement("li");

					if (seriesLabel === options.selectedVerticalSeries)
						$dropdownItem.className = "selected";

					$dropdownItem.innerText = seriesLabel;
					$dropdownItem.onclick = function($event) {
						$($dropdownMenu).children("li").removeClass("selected");
						$($event.currentTarget).addClass("selected");

						options.selectedVerticalSeries = seriesLabel;
						$dropdownButtonValue.innerText = options.selectedVerticalSeries;
						chart.update(options.getVerticalSeriesAndLabels(options.data, seriesLabel), chart.options, true);
					};

					$($dropdownMenu).prepend($dropdownItem);
				});

				$seriesSelection.appendChild($dropdownWrapper);
			}
			else {
				// -------------------- LINE & BAR CHART SERIES SELECTION ------------------


				//Dropdown Button
				$dropdownButton.className = "btn btn-default dropdown-toggle";
				$dropdownButton.id = "series-selection-dropdown" + uniqueId;
				$dropdownButton.setAttribute("data-toggle", "dropdown");
				$dropdownButtonLabel.className = "dropdown-label";
				$dropdownButtonLabel.innerText = $translate.instant("modules.visualization.chartist.period")
				$dropdownButtonValue.className = "selected-item";
				$dropdownButtonValue.innerText = $translate.instant('All');
				$caretIcon.className = "fas fa-caret-down";
				$dropdownButton.appendChild($dropdownButtonLabel);
				$dropdownButton.appendChild($dropdownButtonValue);
				$dropdownButton.appendChild($caretIcon);

				//Dropdown Menu
				$dropdownMenu.className = "dropdown-menu";
				$dropdownMenu.setAttribute("aria-labelledby", "series-selection-dropdown" + uniqueId);

				//Dropdown Wrapper
				$dropdownWrapper.className = "chart-dropdown";
				$dropdownWrapper.appendChild($dropdownButton);
				$dropdownWrapper.appendChild($dropdownMenu);

				_.each(options.data.labels, function(seriesLabel) {
					$dropdownItem = document.createElement("li");

					$dropdownItem.innerText = seriesLabel;
					$dropdownItem.onclick = function($event) {
						$($dropdownMenu).children("li").removeClass("selected");
						$($event.currentTarget).addClass("selected");

						$dropdownButtonValue.innerText = seriesLabel;
						if (typeof options.onSeriesClick === 'function') {
							options.onSeriesClick(seriesLabel)
						}
						else {
							chart.update(options.data, chart.options, true);
						}
					};

					$($dropdownMenu).prepend($dropdownItem);
				});

				$seriesSelection.appendChild($dropdownWrapper);
			}
		}
	};

	return Chartist.plugins.seriesSelection;
}));

}.call(window));