/*** IMPORTS FROM imports-loader ***/
(function() {

(function (root, factory) {
	if (typeof define === 'function' && define.amd) {
		define([ 'chartist' ], function (chartist) {
			return (root.returnExportsGlobal = factory(chartist));
		});
	} 
	else if (typeof exports === 'object')
		module.exports = factory(require('chartist'));
	else
		root['Chartist.plugins.infoMessage'] = factory(root.Chartist);
}(this, function (Chartist) {
	var defaultOptions = {
		message: "No data",
		allowToDismissMessage: true,
	};
	var dismissButton = "";

	Chartist.plugins = Chartist.plugins || {};
	
	Chartist.plugins.infoMessage = function (options) {
		options = Chartist.extend({}, defaultOptions, options);
		return function infoMessage(chart) {
			dismissButton = "";
			if (options.allowToDismissMessage)
				dismissButton = "<button type='button' class='dismiss-button' onclick='var closeMiniModalFunc = function(element) { $(element).parent().remove() }(this)' aria-hidden='true'>×</button>";

			var html = $("<div class='info-message'>" + dismissButton + "<div class='message'><i class='fa fa-info-circle'></i><span>" + options.message + "<span></div></div>");
			var chartElement = chart.container;
			if (chartElement) {
				if (typeof chartElement.append === "function")
					chartElement.append($(html)[0]);
				else
					$(chartElement).append($(html)[0]); //IE 10 support
			}
		}
	};

	return Chartist.plugins.infoMessage;
}));
}.call(window));