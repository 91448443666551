/*** IMPORTS FROM imports-loader ***/
(function() {

(function (root, factory) {
	if (typeof define === 'function' && define.amd) {
		define([ 'chartist' ], function (chartist) {
			return (root.returnExportsGlobal = factory(chartist));
		});
	} 
	else if (typeof exports === 'object')
		module.exports = factory(require('chartist'));
	else
		root['Chartist.plugins.dynamicBarWidth'] = factory(root.Chartist);
}(this, function (Chartist) {

	var defaultOptions = {};

	Chartist.plugins = Chartist.plugins || {};
	
	Chartist.plugins.dynamicBarWidth = function (options) {
		options = Chartist.extend({}, defaultOptions, options);
		return function dynamicBarWidth(chart) {
			if (chart instanceof Chartist.Bar) {
				chart.on('draw', function(data) {
					if (data.type === "bar")
						$(data.element.getNode()).css("stroke-width", chart.options.barWidth + "px");
				});
			}
		}
	};

	return Chartist.plugins.dynamicBarWidth;
}));
}.call(window));