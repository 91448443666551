'use strict';

readMore.$inject = ["$templateCache"];
angular
	.module('hm.readmore', ['ngAnimate', 'ngSanitize'])
	.directive('hmReadMore', readMore)
	.config(["$logProvider", function ($logProvider) {
		$logProvider.debugEnabled(false);
	}]);

/** @ngInject */
function readMore($templateCache) {
	hmReadMoreController.$inject = ["$filter", "$scope", "$log", "$element", "$timeout", "$attrs"];
	var directive = {
		restrict: 'AE',
		scope: {
			hmText: '@',
			hmLimit: '@',
			hmMoreText: '@',
			hmLessText: '@',
			hmDotsClass: '@',
			hmLinkClass: '@'
		},
		template: $templateCache.get('readmore.template.html'),
		controller: hmReadMoreController,
		controllerAs: 'vm',
		bindToController: true
	};

	return directive;

	/** @ngInject */
	// "bindToController: true" binds scope variables to Controller
	function hmReadMoreController($filter, $scope, $log, $element, $timeout, $attrs) {
		var vm = this;
		var wfItemElement = $element.closest('wf-item-component');
		var focus = $attrs.hmFocus != undefined ? true : false;

		vm.toggle = {
			dots: '...',
			dotsClass: vm.hmDotsClass,
			linkClass: vm.hmLinkClass
		}

		// Toggle functions
		function setToggleMoreText() {
			$log.debug('setToggleMoreText');
			vm.toggle.moreText = vm.hmMoreText || 'Read more';
		}

		function setToggleLessText() {
			$log.debug('setToggleLessText');
			vm.toggle.lessText = vm.hmLessText || 'Read less';
		}

		function setCurrentToggleText() {
			$log.debug('setCurrentToggleText');
			vm.toggle.text = vm.toggle.state ? vm.toggle.lessText : vm.toggle.moreText;
		}

		function setShowToggle() {
			$log.debug('setShowToggle');
			vm.toggle.show = vm.moreText && vm.moreText.length > 0;
		}

		function toggle() {
			$log.debug('doToggle');
			vm.toggle.state = !vm.toggle.state;
			vm.showMoreText = !vm.showMoreText;
			setCurrentToggleText();
		}

		vm.doToggle = function () {
			if (focus && wfItemElement.length != 0) {
				var windowHeight = $(window).height();
				var currentScroll = $(window).scrollTop();
				var elementOffsetTop = wfItemElement.offset().top;
				var scrollTo = wfItemElement.offset().top - Math.round(windowHeight / 3);

				if ((vm.toggle.state && currentScroll > elementOffsetTop)) {
					//&& vm.toggle.state == true -- add this if you want to animate only when closing
					$('html, body').animate({ scrollTop: scrollTo }, 400, function() { 
						wfItemElement.toggleClass("pulse-background", true);
					}).promise().then(function() { //promise is needed to execute the toggle once, otherwise, it executes twice because of $('html, body')
						toggle();
						$timeout();
					});
					wfItemElement.toggleClass("pulse-background", false);
				} 
				else
					toggle();
			}
			else 
				toggle();
		}

		$scope.$watch('vm.hmMoreText', function (newValue, oldValue) {
			if (newValue != oldValue) {
				$log.debug('hmMoreText changed');
				setToggleMoreText();
				setCurrentToggleText();
			}
		});

		$scope.$watch('vm.hmLessText', function (newValue, oldValue) {
			if (newValue != oldValue) {
				$log.debug('hmLessText changed');
				setToggleLessText();
				setCurrentToggleText();
			}
		});

		$scope.$watch('vm.hmDotsClass', function (newValue, oldValue) {
			if (newValue != oldValue) {
				$log.debug('hmDotsClass changed');
				vm.toggle.dotsClass = vm.hmDotsClass;
			}
		});

		$scope.$watch('vm.hmLinkClass', function (newValue, oldValue) {
			if (newValue != oldValue) {
				$log.debug('hmLinkClass changed');
				vm.toggle.linkClass = vm.hmLinkClass;
			}
		});

		// ----------

		// If negative number, set to undefined
		function validateLimit() {
			$log.debug('validateLimit');
			vm.hmLimit = (vm.hmLimit && vm.hmLimit <= 0) ? undefined : vm.hmLimit;
		}

		function getMoreTextLimit() {
			$log.debug('getMoreTextLimit');
			return vm.hmLimit && vm.hmLimit < vm.hmText.length ? vm.hmLimit - vm.hmText.length : 0;
		}

		function setLessAndMoreText() {
			$log.debug('setLessAndMoreText');
			vm.lessText = $filter('limitTo')(vm.hmText, vm.hmLimit);
			vm.moreText = $filter('limitTo')(vm.hmText, getMoreTextLimit());
		}

		function initialize() {
			$log.debug('initialize');
			setToggleMoreText();
			setToggleLessText();
			validateLimit();
			setLessAndMoreText();
			setShowToggle();
			setCurrentToggleText();
		}

		initialize();

		$scope.$watch('vm.hmText', function (newValue, oldValue) {
			if (newValue != oldValue) {
				$log.debug('hmText changed');
				validateLimit();
				setLessAndMoreText();
				setShowToggle();
			}
		});

		$scope.$watch('vm.hmLimit', function (newValue, oldValue) {
			if (newValue != oldValue) {
				$log.debug('hmLimit changed');
				validateLimit();
				setLessAndMoreText();
				setShowToggle();
			}
		});
	}
};

angular.module("hm.readmore").run(["$templateCache", function ($templateCache) { $templateCache.put("readmore.template.html", "<span name=\"text\"><span ng-bind-html=\"vm.lessText\" style=\"white-space:pre-wrap;\"></span><span ng-show=\"vm.showMoreText\" class=\"more-show-hide\" ng-bind-html=\"vm.moreText\" style=\"white-space:pre-wrap;\"></span></span><span name=\"toggle\" class=\"toggler\" ng-show=\"vm.toggle.show\" ng-class=\"{readMore: !vm.showMoreText, readLess: vm.showMoreText }\"><span ng-class=\"vm.toggle.dotsClass\" ng-show=\"!vm.toggle.state\" ng-bind=\"::vm.toggle.dots\"></span>\n	<a ng-class=\"vm.toggle.linkClass\" ng-click=\"vm.doToggle()\" ng-bind=\"vm.toggle.text\"></a>\n</span>\n"); }]);