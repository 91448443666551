!function () {
  angular.module("angular-jwt", ["angular-jwt.options", "angular-jwt.interceptor", "angular-jwt.jwt", "angular-jwt.authManager"]), angular.module("angular-jwt.authManager", []).provider("authManager", function () {
    this.$get = ["$rootScope", "$injector", "$location", "jwtHelper", "jwtInterceptor", "jwtOptions", function (t, e, r, n, a, i) {
      function o(t) {
        var r = null;
        return r = Array.isArray(t) ? e.invoke(t, this, {
          options: null
        }) : t();
      }

      function u(t) {
        if (Array.isArray(t) || angular.isFunction(t)) return e.invoke(t, f, {});
        throw new Error("unauthenticatedRedirector must be a function");
      }

      function s() {
        t.isAuthenticated = !0;
      }

      function h() {
        t.isAuthenticated = !1;
      }

      function c() {
        t.$on("$locationChangeStart", function () {
          var e = o(f.tokenGetter);
          e && (n.isTokenExpired(e) ? t.$broadcast("tokenHasExpired", e) : s());
        });
      }

      function l() {
        t.$on("unauthenticated", function () {
          u(f.unauthenticatedRedirector), h();
        });
      }

      function d(t, e) {
        if (!e) return !1;
        var r = e.$$route ? e.$$route : e.data;

        if (r && r.requiresLogin === !0) {
          var a = o(f.tokenGetter);
          (!a || n.isTokenExpired(a)) && (t.preventDefault(), u(f.unauthenticatedRedirector));
        }
      }

      var f = i.getConfig();
      t.isAuthenticated = !1;
      var p = e.has("$state") ? "$stateChangeStart" : "$routeChangeStart";
      return t.$on(p, d), {
        authenticate: s,
        unauthenticate: h,
        getToken: function getToken() {
          return o(f.tokenGetter);
        },
        redirect: function redirect() {
          return u(f.unauthenticatedRedirector);
        },
        checkAuthOnRefresh: c,
        redirectWhenUnauthenticated: l
      };
    }];
  }), angular.module("angular-jwt.interceptor", []).provider("jwtInterceptor", function () {
    this.urlParam, this.authHeader, this.authPrefix, this.whiteListedDomains, this.tokenGetter;
    var t = this;
    this.$get = ["$q", "$injector", "$rootScope", "urlUtils", "jwtOptions", function (e, r, n, a, i) {
      function o(t) {
        if (!a.isSameOrigin(t) && !u.whiteListedDomains.length) throw new Error("As of v0.1.0, requests to domains other than the application's origin must be white listed. Use jwtOptionsProvider.config({ whiteListedDomains: [<domain>] }); to whitelist.");

        for (var e = a.urlResolve(t).hostname.toLowerCase(), r = 0; r < u.whiteListedDomains.length; r++) {
          var n = u.whiteListedDomains[r].toLowerCase();
          if (n === e) return !0;
        }

        return a.isSameOrigin(t) ? !0 : !1;
      }

      var u = angular.extend({}, i.getConfig(), t);
      return {
        request: function request(t) {
          if (t.skipAuthorization || !o(t.url)) return t;

          if (u.urlParam) {
            if (t.params = t.params || {}, t.params[u.urlParam]) return t;
          } else if (t.headers = t.headers || {}, t.headers[u.authHeader]) return t;

          var n = e.when(r.invoke(u.tokenGetter, this, {
            options: t
          }));
          return n.then(function (e) {
            return e && (u.urlParam ? t.params[u.urlParam] = e : t.headers[u.authHeader] = u.authPrefix + e), t;
          });
        },
        responseError: function responseError(t) {
          return 401 === t.status && n.$broadcast("unauthenticated", t), e.reject(t);
        }
      };
    }];
  }), angular.module("angular-jwt.jwt", []).service("jwtHelper", ["$window", function (t) {
    this.urlBase64Decode = function (e) {
      var r = e.replace(/-/g, "+").replace(/_/g, "/");

      switch (r.length % 4) {
        case 0:
          break;

        case 2:
          r += "==";
          break;

        case 3:
          r += "=";
          break;

        default:
          throw "Illegal base64url string!";
      }

      return t.decodeURIComponent(escape(t.atob(r)));
    }, this.decodeToken = function (t) {
      var e = t.split(".");
      if (3 !== e.length) throw new Error("JWT must have 3 parts");
      var r = this.urlBase64Decode(e[1]);
      if (!r) throw new Error("Cannot decode the token");
      return angular.fromJson(r);
    }, this.getTokenExpirationDate = function (t) {
      var e = this.decodeToken(t);
      if ("undefined" == typeof e.exp) return null;
      var r = new Date(0);
      return r.setUTCSeconds(e.exp), r;
    }, this.isTokenExpired = function (t, e) {
      var r = this.getTokenExpirationDate(t);
      return e = e || 0, null === r ? !1 : !(r.valueOf() > new Date().valueOf() + 1e3 * e);
    };
  }]), angular.module("angular-jwt.options", []).provider("jwtOptions", function () {
    var t = {};
    this.config = function (e) {
      t = e;
    }, this.$get = function () {
      function e() {
        this.config = angular.extend({}, r, t);
      }

      var r = {
        urlParam: null,
        authHeader: "Authorization",
        authPrefix: "Bearer ",
        whiteListedDomains: [],
        tokenGetter: function tokenGetter() {
          return null;
        },
        loginPath: "/",
        unauthenticatedRedirectPath: "/",
        unauthenticatedRedirector: ["$location", function (t) {
          t.path(this.unauthenticatedRedirectPath);
        }]
      };
      return e.prototype.getConfig = function () {
        return this.config;
      }, new e();
    };
  }), angular.module("angular-jwt.interceptor").service("urlUtils", function () {
    function t(t) {
      var e = t;
      return r.setAttribute("href", e), e = r.href, r.setAttribute("href", e), {
        href: r.href,
        protocol: r.protocol ? r.protocol.replace(/:$/, "") : "",
        host: r.host,
        search: r.search ? r.search.replace(/^\?/, "") : "",
        hash: r.hash ? r.hash.replace(/^#/, "") : "",
        hostname: r.hostname,
        port: r.port,
        pathname: "/" === r.pathname.charAt(0) ? r.pathname : "/" + r.pathname
      };
    }

    function e(e) {
      var r = angular.isString(e) ? t(e) : e;
      return r.protocol === n.protocol && r.host === n.host;
    }

    var r = document.createElement("a"),
        n = t(window.location.href);
    return {
      urlResolve: t,
      isSameOrigin: e
    };
  });
}();