/*** IMPORTS FROM imports-loader ***/
(function() {

(function (root, factory) {
	if (typeof define === 'function' && define.amd) {
		define([ 'chartist' ], function (chartist) {
			return (root.returnExportsGlobal = factory(chartist));
		});
	} 
	else if (typeof exports === 'object')
		module.exports = factory(require('chartist'));
	else
		root['Chartist.plugins.positionChartCenter'] = factory(root.Chartist);
}(this, function (Chartist) {

	var defaultOptions = {
		gridColor: ""
	};

	Chartist.plugins = Chartist.plugins || {};
	
	Chartist.plugins.positionChartCenter = function (options) {
		options = Chartist.extend({}, defaultOptions, options);
		return function positionChartCenter(chart) {
			var labelWidth = 2;
			if (chart instanceof Chartist.Line) {
				chart.on('draw', function(data) {
					if (data.axis && data.axis.chartRect && chart.data.labels) {
						labelWidth = data.axis.chartRect.width() / chart.data.labels.length;
						chart.labelWidth = labelWidth;
					}
					if (data.type === 'grid') {
						//When Y Grid is off fake it somehow
						if (data.axis.units.pos === 'y') {
							data.axis.chartRect.x1 = data.x1 + (labelWidth / 2);
							data.axis.chartRect.x2 = data.x2 + (labelWidth / 2);
							data.element.attr({ style: "stroke: " + options.gridColor });
						}
						if (data.axis.units.pos === 'x') {
							data.element.attr({
								x1: data.x1 + (data.axis.stepLength / 2),
								x2: data.x2 + (data.axis.stepLength / 2),
								style: "stroke: " + options.gridColor
							});
						}
					}
				});
			}
		}
	};

	return Chartist.plugins.positionChartCenter;
}));
}.call(window));