'use strict';

var defaults = require('./core.defaults');

var Element = require('./core.element');

var helpers = require('../helpers/index');

var Ticks = require('./core.ticks');

defaults._set('scale', {
  display: true,
  position: 'left',
  offset: false,
  // grid line settings
  gridLines: {
    display: true,
    color: 'rgba(0, 0, 0, 0.1)',
    lineWidth: 1,
    drawBorder: true,
    drawOnChartArea: true,
    drawTicks: true,
    tickMarkLength: 10,
    zeroLineWidth: 1,
    zeroLineColor: 'rgba(0,0,0,0.25)',
    zeroLineBorderDash: [],
    zeroLineBorderDashOffset: 0.0,
    offsetGridLines: false,
    borderDash: [],
    borderDashOffset: 0.0
  },
  // scale label
  scaleLabel: {
    // display property
    display: false,
    // actual label
    labelString: '',
    // line height
    lineHeight: 1.2,
    // top/bottom padding
    padding: {
      top: 4,
      bottom: 4
    }
  },
  // label settings
  ticks: {
    beginAtZero: false,
    minRotation: 0,
    maxRotation: 50,
    mirror: false,
    padding: 0,
    reverse: false,
    display: true,
    autoSkip: true,
    autoSkipPadding: 0,
    labelOffset: 0,
    // We pass through arrays to be rendered as multiline labels, we convert Others to strings here.
    callback: Ticks.formatters.values,
    minor: {},
    major: {}
  }
});

function labelsFromTicks(ticks) {
  var labels = [];
  var i, ilen;

  for (i = 0, ilen = ticks.length; i < ilen; ++i) {
    labels.push(ticks[i].label);
  }

  return labels;
}

function getLineValue(scale, index, offsetGridLines) {
  var lineValue = scale.getPixelForTick(index);

  if (offsetGridLines) {
    if (index === 0) {
      lineValue -= (scale.getPixelForTick(1) - lineValue) / 2;
    } else {
      lineValue -= (lineValue - scale.getPixelForTick(index - 1)) / 2;
    }
  }

  return lineValue;
}

module.exports = function (Chart) {
  function computeTextSize(context, tick, font) {
    return helpers.isArray(tick) ? helpers.longestText(context, font, tick) : context.measureText(tick).width;
  }

  function parseFontOptions(options) {
    var valueOrDefault = helpers.valueOrDefault;
    var globalDefaults = defaults.global;
    var size = valueOrDefault(options.fontSize, globalDefaults.defaultFontSize);
    var style = valueOrDefault(options.fontStyle, globalDefaults.defaultFontStyle);
    var family = valueOrDefault(options.fontFamily, globalDefaults.defaultFontFamily);
    return {
      size: size,
      style: style,
      family: family,
      font: helpers.fontString(size, style, family)
    };
  }

  function parseLineHeight(options) {
    return helpers.options.toLineHeight(helpers.valueOrDefault(options.lineHeight, 1.2), helpers.valueOrDefault(options.fontSize, defaults.global.defaultFontSize));
  }

  Chart.Scale = Element.extend({
    /**
     * Get the padding needed for the scale
     * @method getPadding
     * @private
     * @returns {Padding} the necessary padding
     */
    getPadding: function getPadding() {
      var me = this;
      return {
        left: me.paddingLeft || 0,
        top: me.paddingTop || 0,
        right: me.paddingRight || 0,
        bottom: me.paddingBottom || 0
      };
    },

    /**
     * Returns the scale tick objects ({label, major})
     * @since 2.7
     */
    getTicks: function getTicks() {
      return this._ticks;
    },
    // These methods are ordered by lifecyle. Utilities then follow.
    // Any function defined here is inherited by all scale types.
    // Any function can be extended by the scale type
    mergeTicksOptions: function mergeTicksOptions() {
      var ticks = this.options.ticks;

      if (ticks.minor === false) {
        ticks.minor = {
          display: false
        };
      }

      if (ticks.major === false) {
        ticks.major = {
          display: false
        };
      }

      for (var key in ticks) {
        if (key !== 'major' && key !== 'minor') {
          if (typeof ticks.minor[key] === 'undefined') {
            ticks.minor[key] = ticks[key];
          }

          if (typeof ticks.major[key] === 'undefined') {
            ticks.major[key] = ticks[key];
          }
        }
      }
    },
    beforeUpdate: function beforeUpdate() {
      helpers.callback(this.options.beforeUpdate, [this]);
    },
    update: function update(maxWidth, maxHeight, margins) {
      var me = this;
      var i, ilen, labels, label, ticks, tick; // Update Lifecycle - Probably don't want to ever extend or overwrite this function ;)

      me.beforeUpdate(); // Absorb the master measurements

      me.maxWidth = maxWidth;
      me.maxHeight = maxHeight;
      me.margins = helpers.extend({
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }, margins);
      me.longestTextCache = me.longestTextCache || {}; // Dimensions

      me.beforeSetDimensions();
      me.setDimensions();
      me.afterSetDimensions(); // Data min/max

      me.beforeDataLimits();
      me.determineDataLimits();
      me.afterDataLimits(); // Ticks - `this.ticks` is now DEPRECATED!
      // Internal ticks are now stored as objects in the PRIVATE `this._ticks` member
      // and must not be accessed directly from outside this class. `this.ticks` being
      // around for long time and not marked as private, we can't change its structure
      // without unexpected breaking changes. If you need to access the scale ticks,
      // use scale.getTicks() instead.

      me.beforeBuildTicks(); // New implementations should return an array of objects but for BACKWARD COMPAT,
      // we still support no return (`this.ticks` internally set by calling this method).

      ticks = me.buildTicks() || [];
      me.afterBuildTicks();
      me.beforeTickToLabelConversion(); // New implementations should return the formatted tick labels but for BACKWARD
      // COMPAT, we still support no return (`this.ticks` internally changed by calling
      // this method and supposed to contain only string values).

      labels = me.convertTicksToLabels(ticks) || me.ticks;
      me.afterTickToLabelConversion();
      me.ticks = labels; // BACKWARD COMPATIBILITY
      // IMPORTANT: from this point, we consider that `this.ticks` will NEVER change!
      // BACKWARD COMPAT: synchronize `_ticks` with labels (so potentially `this.ticks`)

      for (i = 0, ilen = labels.length; i < ilen; ++i) {
        label = labels[i];
        tick = ticks[i];

        if (!tick) {
          ticks.push(tick = {
            label: label,
            major: false
          });
        } else {
          tick.label = label;
        }
      }

      me._ticks = ticks; // Tick Rotation

      me.beforeCalculateTickRotation();
      me.calculateTickRotation();
      me.afterCalculateTickRotation(); // Fit

      me.beforeFit();
      me.fit();
      me.afterFit(); //

      me.afterUpdate();
      return me.minSize;
    },
    afterUpdate: function afterUpdate() {
      helpers.callback(this.options.afterUpdate, [this]);
    },
    //
    beforeSetDimensions: function beforeSetDimensions() {
      helpers.callback(this.options.beforeSetDimensions, [this]);
    },
    setDimensions: function setDimensions() {
      var me = this; // Set the unconstrained dimension before label rotation

      if (me.isHorizontal()) {
        // Reset position before calculating rotation
        me.width = me.maxWidth;
        me.left = 0;
        me.right = me.width;
      } else {
        me.height = me.maxHeight; // Reset position before calculating rotation

        me.top = 0;
        me.bottom = me.height;
      } // Reset padding


      me.paddingLeft = 0;
      me.paddingTop = 0;
      me.paddingRight = 0;
      me.paddingBottom = 0;
    },
    afterSetDimensions: function afterSetDimensions() {
      helpers.callback(this.options.afterSetDimensions, [this]);
    },
    // Data limits
    beforeDataLimits: function beforeDataLimits() {
      helpers.callback(this.options.beforeDataLimits, [this]);
    },
    determineDataLimits: helpers.noop,
    afterDataLimits: function afterDataLimits() {
      helpers.callback(this.options.afterDataLimits, [this]);
    },
    //
    beforeBuildTicks: function beforeBuildTicks() {
      helpers.callback(this.options.beforeBuildTicks, [this]);
    },
    buildTicks: helpers.noop,
    afterBuildTicks: function afterBuildTicks() {
      helpers.callback(this.options.afterBuildTicks, [this]);
    },
    beforeTickToLabelConversion: function beforeTickToLabelConversion() {
      helpers.callback(this.options.beforeTickToLabelConversion, [this]);
    },
    convertTicksToLabels: function convertTicksToLabels() {
      var me = this; // Convert ticks to strings

      var tickOpts = me.options.ticks;
      me.ticks = me.ticks.map(tickOpts.userCallback || tickOpts.callback, this);
    },
    afterTickToLabelConversion: function afterTickToLabelConversion() {
      helpers.callback(this.options.afterTickToLabelConversion, [this]);
    },
    //
    beforeCalculateTickRotation: function beforeCalculateTickRotation() {
      helpers.callback(this.options.beforeCalculateTickRotation, [this]);
    },
    calculateTickRotation: function calculateTickRotation() {
      var me = this;
      var context = me.ctx;
      var tickOpts = me.options.ticks;
      var labels = labelsFromTicks(me._ticks); // Get the width of each grid by calculating the difference
      // between x offsets between 0 and 1.

      var tickFont = parseFontOptions(tickOpts);
      context.font = tickFont.font;
      var labelRotation = tickOpts.minRotation || 0;

      if (labels.length && me.options.display && me.isHorizontal()) {
        var originalLabelWidth = helpers.longestText(context, tickFont.font, labels, me.longestTextCache);
        var labelWidth = originalLabelWidth;
        var cosRotation, sinRotation; // Allow 3 pixels x2 padding either side for label readability

        var tickWidth = me.getPixelForTick(1) - me.getPixelForTick(0) - 6; // Max label rotation can be set or default to 90 - also act as a loop counter

        while (labelWidth > tickWidth && labelRotation < tickOpts.maxRotation) {
          var angleRadians = helpers.toRadians(labelRotation);
          cosRotation = Math.cos(angleRadians);
          sinRotation = Math.sin(angleRadians);

          if (sinRotation * originalLabelWidth > me.maxHeight) {
            // go back one step
            labelRotation--;
            break;
          }

          labelRotation++;
          labelWidth = cosRotation * originalLabelWidth;
        }
      }

      me.labelRotation = labelRotation;
    },
    afterCalculateTickRotation: function afterCalculateTickRotation() {
      helpers.callback(this.options.afterCalculateTickRotation, [this]);
    },
    //
    beforeFit: function beforeFit() {
      helpers.callback(this.options.beforeFit, [this]);
    },
    fit: function fit() {
      var me = this; // Reset

      var minSize = me.minSize = {
        width: 0,
        height: 0
      };
      var labels = labelsFromTicks(me._ticks);
      var opts = me.options;
      var tickOpts = opts.ticks;
      var scaleLabelOpts = opts.scaleLabel;
      var gridLineOpts = opts.gridLines;
      var display = opts.display;
      var isHorizontal = me.isHorizontal();
      var tickFont = parseFontOptions(tickOpts);
      var tickMarkLength = opts.gridLines.tickMarkLength; // Width

      if (isHorizontal) {
        // subtract the margins to line up with the chartArea if we are a full width scale
        minSize.width = me.isFullWidth() ? me.maxWidth - me.margins.left - me.margins.right : me.maxWidth;
      } else {
        minSize.width = display && gridLineOpts.drawTicks ? tickMarkLength : 0;
      } // height


      if (isHorizontal) {
        minSize.height = display && gridLineOpts.drawTicks ? tickMarkLength : 0;
      } else {
        minSize.height = me.maxHeight; // fill all the height
      } // Are we showing a title for the scale?


      if (scaleLabelOpts.display && display) {
        var scaleLabelLineHeight = parseLineHeight(scaleLabelOpts);
        var scaleLabelPadding = helpers.options.toPadding(scaleLabelOpts.padding);
        var deltaHeight = scaleLabelLineHeight + scaleLabelPadding.height;

        if (isHorizontal) {
          minSize.height += deltaHeight;
        } else {
          minSize.width += deltaHeight;
        }
      } // Don't bother fitting the ticks if we are not showing them


      if (tickOpts.display && display) {
        var largestTextWidth = helpers.longestText(me.ctx, tickFont.font, labels, me.longestTextCache);
        var tallestLabelHeightInLines = helpers.numberOfLabelLines(labels);
        var lineSpace = tickFont.size * 0.5;
        var tickPadding = me.options.ticks.padding;

        if (isHorizontal) {
          // A horizontal axis is more constrained by the height.
          me.longestLabelWidth = largestTextWidth;
          var angleRadians = helpers.toRadians(me.labelRotation);
          var cosRotation = Math.cos(angleRadians);
          var sinRotation = Math.sin(angleRadians); // TODO - improve this calculation

          var labelHeight = sinRotation * largestTextWidth + tickFont.size * tallestLabelHeightInLines + lineSpace * (tallestLabelHeightInLines - 1) + lineSpace; // padding

          minSize.height = Math.min(me.maxHeight, minSize.height + labelHeight + tickPadding);
          me.ctx.font = tickFont.font;
          var firstLabelWidth = computeTextSize(me.ctx, labels[0], tickFont.font);
          var lastLabelWidth = computeTextSize(me.ctx, labels[labels.length - 1], tickFont.font); // Ensure that our ticks are always inside the canvas. When rotated, ticks are right aligned
          // which means that the right padding is dominated by the font height

          if (me.labelRotation !== 0) {
            me.paddingLeft = opts.position === 'bottom' ? cosRotation * firstLabelWidth + 3 : cosRotation * lineSpace + 3; // add 3 px to move away from canvas edges

            me.paddingRight = opts.position === 'bottom' ? cosRotation * lineSpace + 3 : cosRotation * lastLabelWidth + 3;
          } else {
            me.paddingLeft = firstLabelWidth / 2 + 3; // add 3 px to move away from canvas edges

            me.paddingRight = lastLabelWidth / 2 + 3;
          }
        } else {
          // A vertical axis is more constrained by the width. Labels are the
          // dominant factor here, so get that length first and account for padding
          if (tickOpts.mirror) {
            largestTextWidth = 0;
          } else {
            // use lineSpace for consistency with horizontal axis
            // tickPadding is not implemented for horizontal
            largestTextWidth += tickPadding + lineSpace;
          }

          minSize.width = Math.min(me.maxWidth, minSize.width + largestTextWidth);
          me.paddingTop = tickFont.size / 2;
          me.paddingBottom = tickFont.size / 2;
        }
      }

      me.handleMargins();
      me.width = minSize.width;
      me.height = minSize.height;
    },

    /**
     * Handle margins and padding interactions
     * @private
     */
    handleMargins: function handleMargins() {
      var me = this;

      if (me.margins) {
        me.paddingLeft = Math.max(me.paddingLeft - me.margins.left, 0);
        me.paddingTop = Math.max(me.paddingTop - me.margins.top, 0);
        me.paddingRight = Math.max(me.paddingRight - me.margins.right, 0);
        me.paddingBottom = Math.max(me.paddingBottom - me.margins.bottom, 0);
      }
    },
    afterFit: function afterFit() {
      helpers.callback(this.options.afterFit, [this]);
    },
    // Shared Methods
    isHorizontal: function isHorizontal() {
      return this.options.position === 'top' || this.options.position === 'bottom';
    },
    isFullWidth: function isFullWidth() {
      return this.options.fullWidth;
    },
    // Get the correct value. NaN bad inputs, If the value type is object get the x or y based on whether we are horizontal or not
    getRightValue: function getRightValue(rawValue) {
      // Null and undefined values first
      if (helpers.isNullOrUndef(rawValue)) {
        return NaN;
      } // isNaN(object) returns true, so make sure NaN is checking for a number; Discard Infinite values


      if (typeof rawValue === 'number' && !isFinite(rawValue)) {
        return NaN;
      } // If it is in fact an object, dive in one more level


      if (rawValue) {
        if (this.isHorizontal()) {
          if (rawValue.x !== undefined) {
            return this.getRightValue(rawValue.x);
          }
        } else if (rawValue.y !== undefined) {
          return this.getRightValue(rawValue.y);
        }
      } // Value is good, return it


      return rawValue;
    },
    // Used to get the value to display in the tooltip for the data at the given index
    // function getLabelForIndex(index, datasetIndex)
    getLabelForIndex: helpers.noop,
    // Used to get data value locations.  Value can either be an index or a numerical value
    getPixelForValue: helpers.noop,
    // Used to get the data value from a given pixel. This is the inverse of getPixelForValue
    getValueForPixel: helpers.noop,
    // Used for tick location, should
    getPixelForTick: function getPixelForTick(index) {
      var me = this;
      var offset = me.options.offset;

      if (me.isHorizontal()) {
        var innerWidth = me.width - (me.paddingLeft + me.paddingRight);
        var tickWidth = innerWidth / Math.max(me._ticks.length - (offset ? 0 : 1), 1);
        var pixel = tickWidth * index + me.paddingLeft;

        if (offset) {
          pixel += tickWidth / 2;
        }

        var finalVal = me.left + Math.round(pixel);
        finalVal += me.isFullWidth() ? me.margins.left : 0;
        return finalVal;
      }

      var innerHeight = me.height - (me.paddingTop + me.paddingBottom);
      return me.top + index * (innerHeight / (me._ticks.length - 1));
    },
    // Utility for getting the pixel location of a percentage of scale
    getPixelForDecimal: function getPixelForDecimal(decimal) {
      var me = this;

      if (me.isHorizontal()) {
        var innerWidth = me.width - (me.paddingLeft + me.paddingRight);
        var valueOffset = innerWidth * decimal + me.paddingLeft;
        var finalVal = me.left + Math.round(valueOffset);
        finalVal += me.isFullWidth() ? me.margins.left : 0;
        return finalVal;
      }

      return me.top + decimal * me.height;
    },
    getBasePixel: function getBasePixel() {
      return this.getPixelForValue(this.getBaseValue());
    },
    getBaseValue: function getBaseValue() {
      var me = this;
      var min = me.min;
      var max = me.max;
      return me.beginAtZero ? 0 : min < 0 && max < 0 ? max : min > 0 && max > 0 ? min : 0;
    },

    /**
     * Returns a subset of ticks to be plotted to avoid overlapping labels.
     * @private
     */
    _autoSkip: function _autoSkip(ticks) {
      var skipRatio;
      var me = this;
      var isHorizontal = me.isHorizontal();
      var optionTicks = me.options.ticks.minor;
      var tickCount = ticks.length;
      var labelRotationRadians = helpers.toRadians(me.labelRotation);
      var cosRotation = Math.cos(labelRotationRadians);
      var longestRotatedLabel = me.longestLabelWidth * cosRotation;
      var result = [];
      var i, tick, shouldSkip; // figure out the maximum number of gridlines to show

      var maxTicks;

      if (optionTicks.maxTicksLimit) {
        maxTicks = optionTicks.maxTicksLimit;
      }

      if (isHorizontal) {
        skipRatio = false;

        if ((longestRotatedLabel + optionTicks.autoSkipPadding) * tickCount > me.width - (me.paddingLeft + me.paddingRight)) {
          skipRatio = 1 + Math.floor((longestRotatedLabel + optionTicks.autoSkipPadding) * tickCount / (me.width - (me.paddingLeft + me.paddingRight)));
        } // if they defined a max number of optionTicks,
        // increase skipRatio until that number is met


        if (maxTicks && tickCount > maxTicks) {
          skipRatio = Math.max(skipRatio, Math.floor(tickCount / maxTicks));
        }
      }

      for (i = 0; i < tickCount; i++) {
        tick = ticks[i]; // Since we always show the last tick,we need may need to hide the last shown one before

        shouldSkip = skipRatio > 1 && i % skipRatio > 0 || i % skipRatio === 0 && i + skipRatio >= tickCount;

        if (shouldSkip && i !== tickCount - 1 || helpers.isNullOrUndef(tick.label)) {
          // leave tick in place but make sure it's not displayed (#4635)
          delete tick.label;
        }

        result.push(tick);
      }

      return result;
    },
    // Actually draw the scale on the canvas
    // @param {rectangle} chartArea : the area of the chart to draw full grid lines on
    draw: function draw(chartArea) {
      var me = this;
      var options = me.options;

      if (!options.display) {
        return;
      }

      var context = me.ctx;
      var globalDefaults = defaults.global;
      var optionTicks = options.ticks.minor;
      var optionMajorTicks = options.ticks.major || optionTicks;
      var gridLines = options.gridLines;
      var scaleLabel = options.scaleLabel;
      var isRotated = me.labelRotation !== 0;
      var isHorizontal = me.isHorizontal();
      var ticks = optionTicks.autoSkip ? me._autoSkip(me.getTicks()) : me.getTicks();
      var tickFontColor = helpers.valueOrDefault(optionTicks.fontColor, globalDefaults.defaultFontColor);
      var tickFont = parseFontOptions(optionTicks);
      var majorTickFontColor = helpers.valueOrDefault(optionMajorTicks.fontColor, globalDefaults.defaultFontColor);
      var majorTickFont = parseFontOptions(optionMajorTicks);
      var tl = gridLines.drawTicks ? gridLines.tickMarkLength : 0;
      var scaleLabelFontColor = helpers.valueOrDefault(scaleLabel.fontColor, globalDefaults.defaultFontColor);
      var scaleLabelFont = parseFontOptions(scaleLabel);
      var scaleLabelPadding = helpers.options.toPadding(scaleLabel.padding);
      var labelRotationRadians = helpers.toRadians(me.labelRotation);
      var itemsToDraw = [];
      var xTickStart = options.position === 'right' ? me.left : me.right - tl;
      var xTickEnd = options.position === 'right' ? me.left + tl : me.right;
      var yTickStart = options.position === 'bottom' ? me.top : me.bottom - tl;
      var yTickEnd = options.position === 'bottom' ? me.top + tl : me.bottom;
      helpers.each(ticks, function (tick, index) {
        // autoskipper skipped this tick (#4635)
        if (tick.label === undefined) {
          return;
        }

        var label = tick.label;
        var lineWidth, lineColor, borderDash, borderDashOffset;

        if (index === me.zeroLineIndex && options.offset === gridLines.offsetGridLines) {
          // Draw the first index specially
          lineWidth = gridLines.zeroLineWidth;
          lineColor = gridLines.zeroLineColor;
          borderDash = gridLines.zeroLineBorderDash;
          borderDashOffset = gridLines.zeroLineBorderDashOffset;
        } else {
          lineWidth = helpers.valueAtIndexOrDefault(gridLines.lineWidth, index);
          lineColor = helpers.valueAtIndexOrDefault(gridLines.color, index);
          borderDash = helpers.valueOrDefault(gridLines.borderDash, globalDefaults.borderDash);
          borderDashOffset = helpers.valueOrDefault(gridLines.borderDashOffset, globalDefaults.borderDashOffset);
        } // Common properties


        var tx1, ty1, tx2, ty2, x1, y1, x2, y2, labelX, labelY;
        var textAlign = 'middle';
        var textBaseline = 'middle';
        var tickPadding = optionTicks.padding;

        if (isHorizontal) {
          var labelYOffset = tl + tickPadding;

          if (options.position === 'bottom') {
            // bottom
            textBaseline = !isRotated ? 'top' : 'middle';
            textAlign = !isRotated ? 'center' : 'right';
            labelY = me.top + labelYOffset;
          } else {
            // top
            textBaseline = !isRotated ? 'bottom' : 'middle';
            textAlign = !isRotated ? 'center' : 'left';
            labelY = me.bottom - labelYOffset;
          }

          var xLineValue = getLineValue(me, index, gridLines.offsetGridLines && ticks.length > 1);

          if (xLineValue < me.left) {
            lineColor = 'rgba(0,0,0,0)';
          }

          xLineValue += helpers.aliasPixel(lineWidth);
          labelX = me.getPixelForTick(index) + optionTicks.labelOffset; // x values for optionTicks (need to consider offsetLabel option)

          tx1 = tx2 = x1 = x2 = xLineValue;
          ty1 = yTickStart;
          ty2 = yTickEnd;
          y1 = chartArea.top;
          y2 = chartArea.bottom;
        } else {
          var isLeft = options.position === 'left';
          var labelXOffset;

          if (optionTicks.mirror) {
            textAlign = isLeft ? 'left' : 'right';
            labelXOffset = tickPadding;
          } else {
            textAlign = isLeft ? 'right' : 'left';
            labelXOffset = tl + tickPadding;
          }

          labelX = isLeft ? me.right - labelXOffset : me.left + labelXOffset;
          var yLineValue = getLineValue(me, index, gridLines.offsetGridLines && ticks.length > 1);

          if (yLineValue < me.top) {
            lineColor = 'rgba(0,0,0,0)';
          }

          yLineValue += helpers.aliasPixel(lineWidth);
          labelY = me.getPixelForTick(index) + optionTicks.labelOffset;
          tx1 = xTickStart;
          tx2 = xTickEnd;
          x1 = chartArea.left;
          x2 = chartArea.right;
          ty1 = ty2 = y1 = y2 = yLineValue;
        }

        itemsToDraw.push({
          tx1: tx1,
          ty1: ty1,
          tx2: tx2,
          ty2: ty2,
          x1: x1,
          y1: y1,
          x2: x2,
          y2: y2,
          labelX: labelX,
          labelY: labelY,
          glWidth: lineWidth,
          glColor: lineColor,
          glBorderDash: borderDash,
          glBorderDashOffset: borderDashOffset,
          rotation: -1 * labelRotationRadians,
          label: label,
          major: tick.major,
          textBaseline: textBaseline,
          textAlign: textAlign
        });
      }); // Draw all of the tick labels, tick marks, and grid lines at the correct places

      helpers.each(itemsToDraw, function (itemToDraw) {
        if (gridLines.display) {
          context.save();
          context.lineWidth = itemToDraw.glWidth;
          context.strokeStyle = itemToDraw.glColor;

          if (context.setLineDash) {
            context.setLineDash(itemToDraw.glBorderDash);
            context.lineDashOffset = itemToDraw.glBorderDashOffset;
          }

          context.beginPath();

          if (gridLines.drawTicks) {
            context.moveTo(itemToDraw.tx1, itemToDraw.ty1);
            context.lineTo(itemToDraw.tx2, itemToDraw.ty2);
          }

          if (gridLines.drawOnChartArea) {
            context.moveTo(itemToDraw.x1, itemToDraw.y1);
            context.lineTo(itemToDraw.x2, itemToDraw.y2);
          }

          context.stroke();
          context.restore();
        }

        if (optionTicks.display) {
          // Make sure we draw text in the correct color and font
          context.save();
          context.translate(itemToDraw.labelX, itemToDraw.labelY);
          context.rotate(itemToDraw.rotation);
          context.font = itemToDraw.major ? majorTickFont.font : tickFont.font;
          context.fillStyle = itemToDraw.major ? majorTickFontColor : tickFontColor;
          context.textBaseline = itemToDraw.textBaseline;
          context.textAlign = itemToDraw.textAlign;
          var label = itemToDraw.label;

          if (helpers.isArray(label)) {
            for (var i = 0, y = 0; i < label.length; ++i) {
              // We just make sure the multiline element is a string here..
              context.fillText('' + label[i], 0, y); // apply same lineSpacing as calculated @ L#320

              y += tickFont.size * 1.5;
            }
          } else {
            context.fillText(label, 0, 0);
          }

          context.restore();
        }
      });

      if (scaleLabel.display) {
        // Draw the scale label
        var scaleLabelX;
        var scaleLabelY;
        var rotation = 0;
        var halfLineHeight = parseLineHeight(scaleLabel) / 2;

        if (isHorizontal) {
          scaleLabelX = me.left + (me.right - me.left) / 2; // midpoint of the width

          scaleLabelY = options.position === 'bottom' ? me.bottom - halfLineHeight - scaleLabelPadding.bottom : me.top + halfLineHeight + scaleLabelPadding.top;
        } else {
          var isLeft = options.position === 'left';
          scaleLabelX = isLeft ? me.left + halfLineHeight + scaleLabelPadding.top : me.right - halfLineHeight - scaleLabelPadding.top;
          scaleLabelY = me.top + (me.bottom - me.top) / 2;
          rotation = isLeft ? -0.5 * Math.PI : 0.5 * Math.PI;
        }

        context.save();
        context.translate(scaleLabelX, scaleLabelY);
        context.rotate(rotation);
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillStyle = scaleLabelFontColor; // render in correct colour

        context.font = scaleLabelFont.font;
        context.fillText(scaleLabel.labelString, 0, 0);
        context.restore();
      }

      if (gridLines.drawBorder) {
        // Draw the line at the edge of the axis
        context.lineWidth = helpers.valueAtIndexOrDefault(gridLines.lineWidth, 0);
        context.strokeStyle = helpers.valueAtIndexOrDefault(gridLines.color, 0);
        var x1 = me.left;
        var x2 = me.right;
        var y1 = me.top;
        var y2 = me.bottom;
        var aliasPixel = helpers.aliasPixel(context.lineWidth);

        if (isHorizontal) {
          y1 = y2 = options.position === 'top' ? me.bottom : me.top;
          y1 += aliasPixel;
          y2 += aliasPixel;
        } else {
          x1 = x2 = options.position === 'left' ? me.right : me.left;
          x1 += aliasPixel;
          x2 += aliasPixel;
        }

        context.beginPath();
        context.moveTo(x1, y1);
        context.lineTo(x2, y2);
        context.stroke();
      }
    }
  });
};