/*** IMPORTS FROM imports-loader ***/
(function() {

(function (root, factory) {
	if (typeof define === 'function' && define.amd) {
		define(['chartist'], function (chartist) {
			return (root.returnExportsGlobal = factory(chartist));
		});
	}
	else if (typeof exports === 'object')
		module.exports = factory(require('chartist'));
	else
		root['Chartist.plugins.animate'] = factory(root.Chartist);
}(this, function (Chartist) {

	var defaultOptions = {};

	Chartist.plugins = Chartist.plugins || {};

	Chartist.plugins.animate = function (options) {
		options = Chartist.extend({}, defaultOptions, options);
		return function animate(chart) {
			chart.on('draw', function (data) {
				if (data.type === "line" || data.type === "area") {
					data.element.animate({
						d: {
							begin: 0,
							dur: 500,
							from: data.path.clone().scale(1, 0).translate(0, data.chartRect.y1).stringify(),
							to: data.path.clone().stringify(),
							easing: Chartist.Svg.Easing.easeInOutSine
						}
					});
				}

				//Point is animated in chartist-plugin-point.js

				if (data.type === "bar") {
					data.element.animate({
						y2: {
							begin: 0,
							dur: 500,
							from: data.y1,
							to: data.y2,
							easing: Chartist.Svg.Easing.easeOutSine,
						}
					});
				}
			});
		}
	};

	return Chartist.plugins.animate;
}));
}.call(window));