/**
 * @namespace Chart
 */
var Chart = require('./core/core')();

Chart.helpers = require('./helpers/index'); // @todo dispatch these helpers into appropriated helpers/helpers.* file and write unit tests!

require('./core/core.helpers')(Chart);

Chart.defaults = require('./core/core.defaults');
Chart.Element = require('./core/core.element');
Chart.elements = require('./elements/index');
Chart.Interaction = require('./core/core.interaction');
Chart.platform = require('./platforms/platform');

require('./core/core.plugin')(Chart);

require('./core/core.animation')(Chart);

require('./core/core.controller')(Chart);

require('./core/core.datasetController')(Chart);

require('./core/core.layoutService')(Chart);

require('./core/core.scaleService')(Chart);

require('./core/core.scale')(Chart);

require('./core/core.tooltip')(Chart);

require('./scales/scale.linearbase')(Chart);

require('./scales/scale.category')(Chart);

require('./scales/scale.linear')(Chart);

require('./scales/scale.logarithmic')(Chart);

require('./scales/scale.radialLinear')(Chart);

require('./scales/scale.time')(Chart); // Controllers must be loaded after elements
// See Chart.core.datasetController.dataElementType


require('./controllers/controller.bar')(Chart);

require('./controllers/controller.bubble')(Chart);

require('./controllers/controller.doughnut')(Chart);

require('./controllers/controller.line')(Chart);

require('./controllers/controller.polarArea')(Chart);

require('./controllers/controller.radar')(Chart);

require('./controllers/controller.scatter')(Chart);

require('./charts/Chart.Bar')(Chart);

require('./charts/Chart.Bubble')(Chart);

require('./charts/Chart.Doughnut')(Chart);

require('./charts/Chart.Line')(Chart);

require('./charts/Chart.PolarArea')(Chart);

require('./charts/Chart.Radar')(Chart);

require('./charts/Chart.Scatter')(Chart); // Loading built-it plugins


var plugins = [];
plugins.push(require('./plugins/plugin.filler')(Chart), require('./plugins/plugin.legend')(Chart), require('./plugins/plugin.title')(Chart));
Chart.plugins.register(plugins);
Chart.platform.initialize();
module.exports = Chart;

if (typeof window !== 'undefined') {
  window.Chart = Chart;
} // DEPRECATIONS

/**
 * Provided for backward compatibility, use Chart.helpers.canvas instead.
 * @namespace Chart.canvasHelpers
 * @deprecated since version 2.6.0
 * @todo remove at version 3
 * @private
 */


Chart.canvasHelpers = Chart.helpers.canvas;