/*** IMPORTS FROM imports-loader ***/
(function() {

(function (root, factory) {
	if (typeof define === 'function' && define.amd) {
		define([ 'chartist' ], function (chartist) {
			return (root.returnExportsGlobal = factory(chartist));
		});
	} 
	else if (typeof exports === 'object')
		module.exports = factory(require('chartist'));
	else
		root['Chartist.plugins.color'] = factory(root.Chartist);
}(this, function (Chartist) {

	var defaultOptions = {};

	Chartist.plugins = Chartist.plugins || {};
	
	Chartist.plugins.color = function (options) {
		options = Chartist.extend({}, defaultOptions, options);
		return function color(chart) {
			if (chart instanceof Chartist.Line) {
				chart.on('draw', function(data) {
					if (data.type !== 'grid' && data.type !== 'label')
						setStrokeColor(data.series.color, data.element);
					if (data.type === 'area')
						setFillColor(data.series.color, data.element);
				});
			}
			if (chart instanceof Chartist.Bar) {
				chart.on('draw', function(data) {
					if (data.type !== 'grid' && data.type !== 'label')
						setStrokeColor(data.series.color, data.element);
				});
			}
			if (chart instanceof Chartist.Pie) {
				chart.on('draw', function(data) {
					if (data.type === 'slice') {
						if (chart.options.donut) {
							data.element._node.style.stroke = data.series.color;
							if (chart.options.donutSolid)
								setFillColor(data.series.color, data.element)
						}
						else {
							setStrokeColor(data.series.color, data.element);
							setFillColor(data.series.color, data.element);
						}
					}
				});
			}
		}
	};

	function setStrokeColor(color, element) {
		element.attr({ style: 'stroke: ' + color + ';' });
	}

	function setFillColor(color, element) {
		element.attr({ style: 'fill: ' + color + ';' });
	}

	return Chartist.plugins.color;
}));
}.call(window));