(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(['module', './clipboard-action', 'tiny-emitter', 'good-listener'], factory);
  } else if (typeof exports !== "undefined") {
    factory(module, require('./clipboard-action'), require('tiny-emitter'), require('good-listener'));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod, global.clipboardAction, global.tinyEmitter, global.goodListener);
    global.clipboard = mod.exports;
  }
})(this, function (module, _clipboardAction, _tinyEmitter, _goodListener) {
  'use strict';

  var _clipboardAction2 = _interopRequireDefault(_clipboardAction);

  var _tinyEmitter2 = _interopRequireDefault(_tinyEmitter);

  var _goodListener2 = _interopRequireDefault(_goodListener);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var Clipboard = function (_Emitter) {
    _inherits(Clipboard, _Emitter);
    /**
     * @param {String|HTMLElement|HTMLCollection|NodeList} trigger
     * @param {Object} options
     */


    function Clipboard(trigger, options) {
      _classCallCheck(this, Clipboard);

      var _this = _possibleConstructorReturn(this, (Clipboard.__proto__ || Object.getPrototypeOf(Clipboard)).call(this));

      _this.resolveOptions(options);

      _this.listenClick(trigger);

      return _this;
    }
    /**
     * Defines if attributes would be resolved using internal setter functions
     * or custom functions that were passed in the constructor.
     * @param {Object} options
     */


    _createClass(Clipboard, [{
      key: 'resolveOptions',
      value: function resolveOptions() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        this.action = typeof options.action === 'function' ? options.action : this.defaultAction;
        this.target = typeof options.target === 'function' ? options.target : this.defaultTarget;
        this.text = typeof options.text === 'function' ? options.text : this.defaultText;
      }
    }, {
      key: 'listenClick',
      value: function listenClick(trigger) {
        var _this2 = this;

        this.listener = (0, _goodListener2.default)(trigger, 'click', function (e) {
          return _this2.onClick(e);
        });
      }
    }, {
      key: 'onClick',
      value: function onClick(e) {
        var trigger = e.delegateTarget || e.currentTarget;

        if (this.clipboardAction) {
          this.clipboardAction = null;
        }

        this.clipboardAction = new _clipboardAction2.default({
          action: this.action(trigger),
          target: this.target(trigger),
          text: this.text(trigger),
          trigger: trigger,
          emitter: this
        });
      }
    }, {
      key: 'defaultAction',
      value: function defaultAction(trigger) {
        return getAttributeValue('action', trigger);
      }
    }, {
      key: 'defaultTarget',
      value: function defaultTarget(trigger) {
        var selector = getAttributeValue('target', trigger);

        if (selector) {
          return document.querySelector(selector);
        }
      }
    }, {
      key: 'defaultText',
      value: function defaultText(trigger) {
        return getAttributeValue('text', trigger);
      }
    }, {
      key: 'destroy',
      value: function destroy() {
        this.listener.destroy();

        if (this.clipboardAction) {
          this.clipboardAction.destroy();
          this.clipboardAction = null;
        }
      }
    }], [{
      key: 'isSupported',
      value: function isSupported() {
        var action = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ['copy', 'cut'];
        var actions = typeof action === 'string' ? [action] : action;
        var support = !!document.queryCommandSupported;
        actions.forEach(function (action) {
          support = support && !!document.queryCommandSupported(action);
        });
        return support;
      }
    }]);

    return Clipboard;
  }(_tinyEmitter2.default);
  /**
   * Helper function to retrieve attribute value.
   * @param {String} suffix
   * @param {Element} element
   */


  function getAttributeValue(suffix, element) {
    var attribute = 'data-clipboard-' + suffix;

    if (!element.hasAttribute(attribute)) {
      return;
    }

    return element.getAttribute(attribute);
  }

  module.exports = Clipboard;
});