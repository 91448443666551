/*** IMPORTS FROM imports-loader ***/
(function() {

(function (root, factory) {
	if (typeof define === 'function' && define.amd) {
		define([ 'chartist' ], function (chartist) {
			return (root.returnExportsGlobal = factory(chartist));
		});
	} 
	else if (typeof exports === 'object')
		module.exports = factory(require('chartist'));
	else
		root['Chartist.plugins.linearGradient'] = factory(root.Chartist);
}(this, function (Chartist) {

	var defaultOptions = {
		linearGradientId: "wfLinearGradientId",
		fromColor: "black",
		toColor: "white"
	};

	Chartist.plugins = Chartist.plugins || {};
	
	Chartist.plugins.linearGradient = function (options) {
		options = Chartist.extend({}, defaultOptions, options);
		return function linearGradient(chart) {
			chart.on('draw', function(data) {
				if (data.type === "area") {
					if (data.group.root().querySelector("#" + options.linearGradientId) === null) {
						data.element.attr({ style: "fill: url('#" + options.linearGradientId + "')" });
						data.group.root().append(getDefsElement(options));
					}
				}
			});
		}
	};

	function getDefsElement(options) {
		var defs = Chartist.Svg("defs");

		var linearGradient = Chartist.Svg("linearGradient", {
			id: options.linearGradientId,
			x1: "0%",
			x2: "0%",
			y1: "20%",
			y2: "100%"
		}, null, defs );

		Chartist.Svg("stop", {
			offset: "80%",
			"stop-opacity": 1,
			"stop-color": options.fromColor,
		}, null, linearGradient );

		Chartist.Svg("stop", {
			offset: "100%",
			"stop-opacity": 1,
			"stop-color": options.toColor,
		}, null, linearGradient );

		return defs;
	}

	return Chartist.plugins.linearGradient;
}));
}.call(window));