'use strict';

var helpers = require('../helpers/index');

var Ticks = require('../core/core.ticks');

module.exports = function (Chart) {
  var defaultConfig = {
    position: 'left',
    // label settings
    ticks: {
      callback: Ticks.formatters.logarithmic
    }
  };
  var LogarithmicScale = Chart.Scale.extend({
    determineDataLimits: function determineDataLimits() {
      var me = this;
      var opts = me.options;
      var tickOpts = opts.ticks;
      var chart = me.chart;
      var data = chart.data;
      var datasets = data.datasets;
      var valueOrDefault = helpers.valueOrDefault;
      var isHorizontal = me.isHorizontal();

      function IDMatches(meta) {
        return isHorizontal ? meta.xAxisID === me.id : meta.yAxisID === me.id;
      } // Calculate Range


      me.min = null;
      me.max = null;
      me.minNotZero = null;
      var hasStacks = opts.stacked;

      if (hasStacks === undefined) {
        helpers.each(datasets, function (dataset, datasetIndex) {
          if (hasStacks) {
            return;
          }

          var meta = chart.getDatasetMeta(datasetIndex);

          if (chart.isDatasetVisible(datasetIndex) && IDMatches(meta) && meta.stack !== undefined) {
            hasStacks = true;
          }
        });
      }

      if (opts.stacked || hasStacks) {
        var valuesPerStack = {};
        helpers.each(datasets, function (dataset, datasetIndex) {
          var meta = chart.getDatasetMeta(datasetIndex);
          var key = [meta.type, // we have a separate stack for stack=undefined datasets when the opts.stacked is undefined
          opts.stacked === undefined && meta.stack === undefined ? datasetIndex : '', meta.stack].join('.');

          if (chart.isDatasetVisible(datasetIndex) && IDMatches(meta)) {
            if (valuesPerStack[key] === undefined) {
              valuesPerStack[key] = [];
            }

            helpers.each(dataset.data, function (rawValue, index) {
              var values = valuesPerStack[key];
              var value = +me.getRightValue(rawValue);

              if (isNaN(value) || meta.data[index].hidden) {
                return;
              }

              values[index] = values[index] || 0;

              if (opts.relativePoints) {
                values[index] = 100;
              } else {
                // Don't need to split positive and negative since the log scale can't handle a 0 crossing
                values[index] += value;
              }
            });
          }
        });
        helpers.each(valuesPerStack, function (valuesForType) {
          var minVal = helpers.min(valuesForType);
          var maxVal = helpers.max(valuesForType);
          me.min = me.min === null ? minVal : Math.min(me.min, minVal);
          me.max = me.max === null ? maxVal : Math.max(me.max, maxVal);
        });
      } else {
        helpers.each(datasets, function (dataset, datasetIndex) {
          var meta = chart.getDatasetMeta(datasetIndex);

          if (chart.isDatasetVisible(datasetIndex) && IDMatches(meta)) {
            helpers.each(dataset.data, function (rawValue, index) {
              var value = +me.getRightValue(rawValue);

              if (isNaN(value) || meta.data[index].hidden) {
                return;
              }

              if (me.min === null) {
                me.min = value;
              } else if (value < me.min) {
                me.min = value;
              }

              if (me.max === null) {
                me.max = value;
              } else if (value > me.max) {
                me.max = value;
              }

              if (value !== 0 && (me.minNotZero === null || value < me.minNotZero)) {
                me.minNotZero = value;
              }
            });
          }
        });
      }

      me.min = valueOrDefault(tickOpts.min, me.min);
      me.max = valueOrDefault(tickOpts.max, me.max);

      if (me.min === me.max) {
        if (me.min !== 0 && me.min !== null) {
          me.min = Math.pow(10, Math.floor(helpers.log10(me.min)) - 1);
          me.max = Math.pow(10, Math.floor(helpers.log10(me.max)) + 1);
        } else {
          me.min = 1;
          me.max = 10;
        }
      }
    },
    buildTicks: function buildTicks() {
      var me = this;
      var opts = me.options;
      var tickOpts = opts.ticks;
      var generationOptions = {
        min: tickOpts.min,
        max: tickOpts.max
      };
      var ticks = me.ticks = Ticks.generators.logarithmic(generationOptions, me);

      if (!me.isHorizontal()) {
        // We are in a vertical orientation. The top value is the highest. So reverse the array
        ticks.reverse();
      } // At this point, we need to update our max and min given the tick values since we have expanded the
      // range of the scale


      me.max = helpers.max(ticks);
      me.min = helpers.min(ticks);

      if (tickOpts.reverse) {
        ticks.reverse();
        me.start = me.max;
        me.end = me.min;
      } else {
        me.start = me.min;
        me.end = me.max;
      }
    },
    convertTicksToLabels: function convertTicksToLabels() {
      this.tickValues = this.ticks.slice();
      Chart.Scale.prototype.convertTicksToLabels.call(this);
    },
    // Get the correct tooltip label
    getLabelForIndex: function getLabelForIndex(index, datasetIndex) {
      return +this.getRightValue(this.chart.data.datasets[datasetIndex].data[index]);
    },
    getPixelForTick: function getPixelForTick(index) {
      return this.getPixelForValue(this.tickValues[index]);
    },
    getPixelForValue: function getPixelForValue(value) {
      var me = this;
      var start = me.start;
      var newVal = +me.getRightValue(value);
      var opts = me.options;
      var tickOpts = opts.ticks;
      var innerDimension, pixel, range;

      if (me.isHorizontal()) {
        range = helpers.log10(me.end) - helpers.log10(start); // todo: if start === 0

        if (newVal === 0) {
          pixel = me.left;
        } else {
          innerDimension = me.width;
          pixel = me.left + innerDimension / range * (helpers.log10(newVal) - helpers.log10(start));
        }
      } else {
        // Bottom - top since pixels increase downward on a screen
        innerDimension = me.height;

        if (start === 0 && !tickOpts.reverse) {
          range = helpers.log10(me.end) - helpers.log10(me.minNotZero);

          if (newVal === start) {
            pixel = me.bottom;
          } else if (newVal === me.minNotZero) {
            pixel = me.bottom - innerDimension * 0.02;
          } else {
            pixel = me.bottom - innerDimension * 0.02 - innerDimension * 0.98 / range * (helpers.log10(newVal) - helpers.log10(me.minNotZero));
          }
        } else if (me.end === 0 && tickOpts.reverse) {
          range = helpers.log10(me.start) - helpers.log10(me.minNotZero);

          if (newVal === me.end) {
            pixel = me.top;
          } else if (newVal === me.minNotZero) {
            pixel = me.top + innerDimension * 0.02;
          } else {
            pixel = me.top + innerDimension * 0.02 + innerDimension * 0.98 / range * (helpers.log10(newVal) - helpers.log10(me.minNotZero));
          }
        } else if (newVal === 0) {
          pixel = tickOpts.reverse ? me.top : me.bottom;
        } else {
          range = helpers.log10(me.end) - helpers.log10(start);
          innerDimension = me.height;
          pixel = me.bottom - innerDimension / range * (helpers.log10(newVal) - helpers.log10(start));
        }
      }

      return pixel;
    },
    getValueForPixel: function getValueForPixel(pixel) {
      var me = this;
      var range = helpers.log10(me.end) - helpers.log10(me.start);
      var value, innerDimension;

      if (me.isHorizontal()) {
        innerDimension = me.width;
        value = me.start * Math.pow(10, (pixel - me.left) * range / innerDimension);
      } else {
        // todo: if start === 0
        innerDimension = me.height;
        value = Math.pow(10, (me.bottom - pixel) * range / innerDimension) / me.start;
      }

      return value;
    }
  });
  Chart.scaleService.registerScaleType('logarithmic', LogarithmicScale, defaultConfig);
};