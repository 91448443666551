/**
 * Chart.Platform implementation for targeting a web browser
 */
'use strict';

var helpers = require('../helpers/index');

var EXPANDO_KEY = '$chartjs';
var CSS_PREFIX = 'chartjs-';
var CSS_RENDER_MONITOR = CSS_PREFIX + 'render-monitor';
var CSS_RENDER_ANIMATION = CSS_PREFIX + 'render-animation';
var ANIMATION_START_EVENTS = ['animationstart', 'webkitAnimationStart'];
/**
 * DOM event types -> Chart.js event types.
 * Note: only events with different types are mapped.
 * @see https://developer.mozilla.org/en-US/docs/Web/Events
 */

var EVENT_TYPES = {
  touchstart: 'mousedown',
  touchmove: 'mousemove',
  touchend: 'mouseup',
  pointerenter: 'mouseenter',
  pointerdown: 'mousedown',
  pointermove: 'mousemove',
  pointerup: 'mouseup',
  pointerleave: 'mouseout',
  pointerout: 'mouseout'
};
/**
 * The "used" size is the final value of a dimension property after all calculations have
 * been performed. This method uses the computed style of `element` but returns undefined
 * if the computed style is not expressed in pixels. That can happen in some cases where
 * `element` has a size relative to its parent and this last one is not yet displayed,
 * for example because of `display: none` on a parent node.
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/used_value
 * @returns {Number} Size in pixels or undefined if unknown.
 */

function readUsedSize(element, property) {
  var value = helpers.getStyle(element, property);
  var matches = value && value.match(/^(\d+)(\.\d+)?px$/);
  return matches ? Number(matches[1]) : undefined;
}
/**
 * Initializes the canvas style and render size without modifying the canvas display size,
 * since responsiveness is handled by the controller.resize() method. The config is used
 * to determine the aspect ratio to apply in case no explicit height has been specified.
 */


function initCanvas(canvas, config) {
  var style = canvas.style; // NOTE(SB) canvas.getAttribute('width') !== canvas.width: in the first case it
  // returns null or '' if no explicit value has been set to the canvas attribute.

  var renderHeight = canvas.getAttribute('height');
  var renderWidth = canvas.getAttribute('width'); // Chart.js modifies some canvas values that we want to restore on destroy

  canvas[EXPANDO_KEY] = {
    initial: {
      height: renderHeight,
      width: renderWidth,
      style: {
        display: style.display,
        height: style.height,
        width: style.width
      }
    }
  }; // Force canvas to display as block to avoid extra space caused by inline
  // elements, which would interfere with the responsive resize process.
  // https://github.com/chartjs/Chart.js/issues/2538

  style.display = style.display || 'block';

  if (renderWidth === null || renderWidth === '') {
    var displayWidth = readUsedSize(canvas, 'width');

    if (displayWidth !== undefined) {
      canvas.width = displayWidth;
    }
  }

  if (renderHeight === null || renderHeight === '') {
    if (canvas.style.height === '') {
      // If no explicit render height and style height, let's apply the aspect ratio,
      // which one can be specified by the user but also by charts as default option
      // (i.e. options.aspectRatio). If not specified, use canvas aspect ratio of 2.
      canvas.height = canvas.width / (config.options.aspectRatio || 2);
    } else {
      var displayHeight = readUsedSize(canvas, 'height');

      if (displayWidth !== undefined) {
        canvas.height = displayHeight;
      }
    }
  }

  return canvas;
}
/**
 * Detects support for options object argument in addEventListener.
 * https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Safely_detecting_option_support
 * @private
 */


var supportsEventListenerOptions = function () {
  var supports = false;

  try {
    var options = Object.defineProperty({}, 'passive', {
      get: function get() {
        supports = true;
      }
    });
    window.addEventListener('e', null, options);
  } catch (e) {// continue regardless of error
  }

  return supports;
}(); // Default passive to true as expected by Chrome for 'touchstart' and 'touchend' events.
// https://github.com/chartjs/Chart.js/issues/4287


var eventListenerOptions = supportsEventListenerOptions ? {
  passive: true
} : false;

function _addEventListener(node, type, listener) {
  node.addEventListener(type, listener, eventListenerOptions);
}

function _removeEventListener(node, type, listener) {
  node.removeEventListener(type, listener, eventListenerOptions);
}

function createEvent(type, chart, x, y, nativeEvent) {
  return {
    type: type,
    chart: chart,
    native: nativeEvent || null,
    x: x !== undefined ? x : null,
    y: y !== undefined ? y : null
  };
}

function fromNativeEvent(event, chart) {
  var type = EVENT_TYPES[event.type] || event.type;
  var pos = helpers.getRelativePosition(event, chart);
  return createEvent(type, chart, pos.x, pos.y, event);
}

function throttled(fn, thisArg) {
  var ticking = false;
  var args = [];
  return function () {
    args = Array.prototype.slice.call(arguments);
    thisArg = thisArg || this;

    if (!ticking) {
      ticking = true;
      helpers.requestAnimFrame.call(window, function () {
        ticking = false;
        fn.apply(thisArg, args);
      });
    }
  };
} // Implementation based on https://github.com/marcj/css-element-queries


function createResizer(handler) {
  var resizer = document.createElement('div');
  var cls = CSS_PREFIX + 'size-monitor';
  var maxSize = 1000000;
  var style = 'position:absolute;' + 'left:0;' + 'top:0;' + 'right:0;' + 'bottom:0;' + 'overflow:hidden;' + 'pointer-events:none;' + 'visibility:hidden;' + 'z-index:-1;';
  resizer.style.cssText = style;
  resizer.className = cls;
  resizer.innerHTML = '<div class="' + cls + '-expand" style="' + style + '">' + '<div style="' + 'position:absolute;' + 'width:' + maxSize + 'px;' + 'height:' + maxSize + 'px;' + 'left:0;' + 'top:0">' + '</div>' + '</div>' + '<div class="' + cls + '-shrink" style="' + style + '">' + '<div style="' + 'position:absolute;' + 'width:200%;' + 'height:200%;' + 'left:0; ' + 'top:0">' + '</div>' + '</div>';
  var expand = resizer.childNodes[0];
  var shrink = resizer.childNodes[1];

  resizer._reset = function () {
    expand.scrollLeft = maxSize;
    expand.scrollTop = maxSize;
    shrink.scrollLeft = maxSize;
    shrink.scrollTop = maxSize;
  };

  var onScroll = function onScroll() {
    resizer._reset();

    handler();
  };

  _addEventListener(expand, 'scroll', onScroll.bind(expand, 'expand'));

  _addEventListener(shrink, 'scroll', onScroll.bind(shrink, 'shrink'));

  return resizer;
} // https://davidwalsh.name/detect-node-insertion


function watchForRender(node, handler) {
  var expando = node[EXPANDO_KEY] || (node[EXPANDO_KEY] = {});

  var proxy = expando.renderProxy = function (e) {
    if (e.animationName === CSS_RENDER_ANIMATION) {
      handler();
    }
  };

  helpers.each(ANIMATION_START_EVENTS, function (type) {
    _addEventListener(node, type, proxy);
  });
  node.classList.add(CSS_RENDER_MONITOR);
}

function unwatchForRender(node) {
  var expando = node[EXPANDO_KEY] || {};
  var proxy = expando.renderProxy;

  if (proxy) {
    helpers.each(ANIMATION_START_EVENTS, function (type) {
      _removeEventListener(node, type, proxy);
    });
    delete expando.renderProxy;
  }

  node.classList.remove(CSS_RENDER_MONITOR);
}

function addResizeListener(node, listener, chart) {
  var expando = node[EXPANDO_KEY] || (node[EXPANDO_KEY] = {}); // Let's keep track of this added resizer and thus avoid DOM query when removing it.

  var resizer = expando.resizer = createResizer(throttled(function () {
    if (expando.resizer) {
      return listener(createEvent('resize', chart));
    }
  })); // The resizer needs to be attached to the node parent, so we first need to be
  // sure that `node` is attached to the DOM before injecting the resizer element.

  watchForRender(node, function () {
    if (expando.resizer) {
      var container = node.parentNode;

      if (container && container !== resizer.parentNode) {
        container.insertBefore(resizer, container.firstChild);
      } // The container size might have changed, let's reset the resizer state.


      resizer._reset();
    }
  });
}

function removeResizeListener(node) {
  var expando = node[EXPANDO_KEY] || {};
  var resizer = expando.resizer;
  delete expando.resizer;
  unwatchForRender(node);

  if (resizer && resizer.parentNode) {
    resizer.parentNode.removeChild(resizer);
  }
}

function injectCSS(platform, css) {
  // http://stackoverflow.com/q/3922139
  var style = platform._style || document.createElement('style');

  if (!platform._style) {
    platform._style = style;
    css = '/* Chart.js */\n' + css;
    style.setAttribute('type', 'text/css');
    document.getElementsByTagName('head')[0].appendChild(style);
  }

  style.appendChild(document.createTextNode(css));
}

module.exports = {
  /**
   * This property holds whether this platform is enabled for the current environment.
   * Currently used by platform.js to select the proper implementation.
   * @private
   */
  _enabled: typeof window !== 'undefined' && typeof document !== 'undefined',
  initialize: function initialize() {
    var keyframes = 'from{opacity:0.99}to{opacity:1}';
    injectCSS(this, // DOM rendering detection
    // https://davidwalsh.name/detect-node-insertion
    '@-webkit-keyframes ' + CSS_RENDER_ANIMATION + '{' + keyframes + '}' + '@keyframes ' + CSS_RENDER_ANIMATION + '{' + keyframes + '}' + '.' + CSS_RENDER_MONITOR + '{' + '-webkit-animation:' + CSS_RENDER_ANIMATION + ' 0.001s;' + 'animation:' + CSS_RENDER_ANIMATION + ' 0.001s;' + '}');
  },
  acquireContext: function acquireContext(item, config) {
    if (typeof item === 'string') {
      item = document.getElementById(item);
    } else if (item.length) {
      // Support for array based queries (such as jQuery)
      item = item[0];
    }

    if (item && item.canvas) {
      // Support for any object associated to a canvas (including a context2d)
      item = item.canvas;
    } // To prevent canvas fingerprinting, some add-ons undefine the getContext
    // method, for example: https://github.com/kkapsner/CanvasBlocker
    // https://github.com/chartjs/Chart.js/issues/2807


    var context = item && item.getContext && item.getContext('2d'); // `instanceof HTMLCanvasElement/CanvasRenderingContext2D` fails when the item is
    // inside an iframe or when running in a protected environment. We could guess the
    // types from their toString() value but let's keep things flexible and assume it's
    // a sufficient condition if the item has a context2D which has item as `canvas`.
    // https://github.com/chartjs/Chart.js/issues/3887
    // https://github.com/chartjs/Chart.js/issues/4102
    // https://github.com/chartjs/Chart.js/issues/4152

    if (context && context.canvas === item) {
      initCanvas(item, config);
      return context;
    }

    return null;
  },
  releaseContext: function releaseContext(context) {
    var canvas = context.canvas;

    if (!canvas[EXPANDO_KEY]) {
      return;
    }

    var initial = canvas[EXPANDO_KEY].initial;
    ['height', 'width'].forEach(function (prop) {
      var value = initial[prop];

      if (helpers.isNullOrUndef(value)) {
        canvas.removeAttribute(prop);
      } else {
        canvas.setAttribute(prop, value);
      }
    });
    helpers.each(initial.style || {}, function (value, key) {
      canvas.style[key] = value;
    }); // The canvas render size might have been changed (and thus the state stack discarded),
    // we can't use save() and restore() to restore the initial state. So make sure that at
    // least the canvas context is reset to the default state by setting the canvas width.
    // https://www.w3.org/TR/2011/WD-html5-20110525/the-canvas-element.html

    canvas.width = canvas.width;
    delete canvas[EXPANDO_KEY];
  },
  addEventListener: function addEventListener(chart, type, listener) {
    var canvas = chart.canvas;

    if (type === 'resize') {
      // Note: the resize event is not supported on all browsers.
      addResizeListener(canvas, listener, chart);
      return;
    }

    var expando = listener[EXPANDO_KEY] || (listener[EXPANDO_KEY] = {});
    var proxies = expando.proxies || (expando.proxies = {});

    var proxy = proxies[chart.id + '_' + type] = function (event) {
      listener(fromNativeEvent(event, chart));
    };

    _addEventListener(canvas, type, proxy);
  },
  removeEventListener: function removeEventListener(chart, type, listener) {
    var canvas = chart.canvas;

    if (type === 'resize') {
      // Note: the resize event is not supported on all browsers.
      removeResizeListener(canvas, listener);
      return;
    }

    var expando = listener[EXPANDO_KEY] || {};
    var proxies = expando.proxies || {};
    var proxy = proxies[chart.id + '_' + type];

    if (!proxy) {
      return;
    }

    _removeEventListener(canvas, type, proxy);
  }
}; // DEPRECATIONS

/**
 * Provided for backward compatibility, use EventTarget.addEventListener instead.
 * EventTarget.addEventListener compatibility: Chrome, Opera 7, Safari, FF1.5+, IE9+
 * @see https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
 * @function Chart.helpers.addEvent
 * @deprecated since version 2.7.0
 * @todo remove at version 3
 * @private
 */

helpers.addEvent = _addEventListener;
/**
 * Provided for backward compatibility, use EventTarget.removeEventListener instead.
 * EventTarget.removeEventListener compatibility: Chrome, Opera 7, Safari, FF1.5+, IE9+
 * @see https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/removeEventListener
 * @function Chart.helpers.removeEvent
 * @deprecated since version 2.7.0
 * @todo remove at version 3
 * @private
 */

helpers.removeEvent = _removeEventListener;