'use strict';

var defaults = require('./core.defaults');

var helpers = require('../helpers/index');

module.exports = function (Chart) {
  Chart.scaleService = {
    // Scale registration object. Extensions can register new scale types (such as log or DB scales) and then
    // use the new chart options to grab the correct scale
    constructors: {},
    // Use a registration function so that we can move to an ES6 map when we no longer need to support
    // old browsers
    // Scale config defaults
    defaults: {},
    registerScaleType: function registerScaleType(type, scaleConstructor, scaleDefaults) {
      this.constructors[type] = scaleConstructor;
      this.defaults[type] = helpers.clone(scaleDefaults);
    },
    getScaleConstructor: function getScaleConstructor(type) {
      return this.constructors.hasOwnProperty(type) ? this.constructors[type] : undefined;
    },
    getScaleDefaults: function getScaleDefaults(type) {
      // Return the scale defaults merged with the global settings so that we always use the latest ones
      return this.defaults.hasOwnProperty(type) ? helpers.merge({}, [defaults.scale, this.defaults[type]]) : {};
    },
    updateScaleDefaults: function updateScaleDefaults(type, additions) {
      var me = this;

      if (me.defaults.hasOwnProperty(type)) {
        me.defaults[type] = helpers.extend(me.defaults[type], additions);
      }
    },
    addScalesToLayout: function addScalesToLayout(chart) {
      // Adds each scale to the chart.boxes array to be sized accordingly
      helpers.each(chart.scales, function (scale) {
        // Set ILayoutItem parameters for backwards compatibility
        scale.fullWidth = scale.options.fullWidth;
        scale.position = scale.options.position;
        scale.weight = scale.options.weight;
        Chart.layoutService.addBox(chart, scale);
      });
    }
  };
};