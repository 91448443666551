'use strict';

var defaults = require('../core/core.defaults');

var Element = require('../core/core.element');

var helpers = require('../helpers/index');

defaults._set('global', {
  legend: {
    display: true,
    position: 'top',
    fullWidth: true,
    reverse: false,
    weight: 1000,
    // a callback that will handle
    onClick: function onClick(e, legendItem) {
      var index = legendItem.datasetIndex;
      var ci = this.chart;
      var meta = ci.getDatasetMeta(index); // See controller.isDatasetVisible comment

      meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null; // We hid a dataset ... rerender the chart

      ci.update();
    },
    onHover: null,
    labels: {
      boxWidth: 40,
      padding: 10,
      // Generates labels shown in the legend
      // Valid properties to return:
      // text : text to display
      // fillStyle : fill of coloured box
      // strokeStyle: stroke of coloured box
      // hidden : if this legend item refers to a hidden item
      // lineCap : cap style for line
      // lineDash
      // lineDashOffset :
      // lineJoin :
      // lineWidth :
      generateLabels: function generateLabels(chart) {
        var data = chart.data;
        return helpers.isArray(data.datasets) ? data.datasets.map(function (dataset, i) {
          return {
            text: dataset.label,
            fillStyle: !helpers.isArray(dataset.backgroundColor) ? dataset.backgroundColor : dataset.backgroundColor[0],
            hidden: !chart.isDatasetVisible(i),
            lineCap: dataset.borderCapStyle,
            lineDash: dataset.borderDash,
            lineDashOffset: dataset.borderDashOffset,
            lineJoin: dataset.borderJoinStyle,
            lineWidth: dataset.borderWidth,
            strokeStyle: dataset.borderColor,
            pointStyle: dataset.pointStyle,
            // Below is extra data used for toggling the datasets
            datasetIndex: i
          };
        }, this) : [];
      }
    }
  },
  legendCallback: function legendCallback(chart) {
    var text = [];
    text.push('<ul class="' + chart.id + '-legend">');

    for (var i = 0; i < chart.data.datasets.length; i++) {
      text.push('<li><span style="background-color:' + chart.data.datasets[i].backgroundColor + '"></span>');

      if (chart.data.datasets[i].label) {
        text.push(chart.data.datasets[i].label);
      }

      text.push('</li>');
    }

    text.push('</ul>');
    return text.join('');
  }
});

module.exports = function (Chart) {
  var layout = Chart.layoutService;
  var noop = helpers.noop;
  /**
   * Helper function to get the box width based on the usePointStyle option
   * @param labelopts {Object} the label options on the legend
   * @param fontSize {Number} the label font size
   * @return {Number} width of the color box area
   */

  function getBoxWidth(labelOpts, fontSize) {
    return labelOpts.usePointStyle ? fontSize * Math.SQRT2 : labelOpts.boxWidth;
  }

  Chart.Legend = Element.extend({
    initialize: function initialize(config) {
      helpers.extend(this, config); // Contains hit boxes for each dataset (in dataset order)

      this.legendHitBoxes = []; // Are we in doughnut mode which has a different data type

      this.doughnutMode = false;
    },
    // These methods are ordered by lifecycle. Utilities then follow.
    // Any function defined here is inherited by all legend types.
    // Any function can be extended by the legend type
    beforeUpdate: noop,
    update: function update(maxWidth, maxHeight, margins) {
      var me = this; // Update Lifecycle - Probably don't want to ever extend or overwrite this function ;)

      me.beforeUpdate(); // Absorb the master measurements

      me.maxWidth = maxWidth;
      me.maxHeight = maxHeight;
      me.margins = margins; // Dimensions

      me.beforeSetDimensions();
      me.setDimensions();
      me.afterSetDimensions(); // Labels

      me.beforeBuildLabels();
      me.buildLabels();
      me.afterBuildLabels(); // Fit

      me.beforeFit();
      me.fit();
      me.afterFit(); //

      me.afterUpdate();
      return me.minSize;
    },
    afterUpdate: noop,
    //
    beforeSetDimensions: noop,
    setDimensions: function setDimensions() {
      var me = this; // Set the unconstrained dimension before label rotation

      if (me.isHorizontal()) {
        // Reset position before calculating rotation
        me.width = me.maxWidth;
        me.left = 0;
        me.right = me.width;
      } else {
        me.height = me.maxHeight; // Reset position before calculating rotation

        me.top = 0;
        me.bottom = me.height;
      } // Reset padding


      me.paddingLeft = 0;
      me.paddingTop = 0;
      me.paddingRight = 0;
      me.paddingBottom = 0; // Reset minSize

      me.minSize = {
        width: 0,
        height: 0
      };
    },
    afterSetDimensions: noop,
    //
    beforeBuildLabels: noop,
    buildLabels: function buildLabels() {
      var me = this;
      var labelOpts = me.options.labels || {};
      var legendItems = helpers.callback(labelOpts.generateLabels, [me.chart], me) || [];

      if (labelOpts.filter) {
        legendItems = legendItems.filter(function (item) {
          return labelOpts.filter(item, me.chart.data);
        });
      }

      if (me.options.reverse) {
        legendItems.reverse();
      }

      me.legendItems = legendItems;
    },
    afterBuildLabels: noop,
    //
    beforeFit: noop,
    fit: function fit() {
      var me = this;
      var opts = me.options;
      var labelOpts = opts.labels;
      var display = opts.display;
      var ctx = me.ctx;
      var globalDefault = defaults.global;
      var valueOrDefault = helpers.valueOrDefault;
      var fontSize = valueOrDefault(labelOpts.fontSize, globalDefault.defaultFontSize);
      var fontStyle = valueOrDefault(labelOpts.fontStyle, globalDefault.defaultFontStyle);
      var fontFamily = valueOrDefault(labelOpts.fontFamily, globalDefault.defaultFontFamily);
      var labelFont = helpers.fontString(fontSize, fontStyle, fontFamily); // Reset hit boxes

      var hitboxes = me.legendHitBoxes = [];
      var minSize = me.minSize;
      var isHorizontal = me.isHorizontal();

      if (isHorizontal) {
        minSize.width = me.maxWidth; // fill all the width

        minSize.height = display ? 10 : 0;
      } else {
        minSize.width = display ? 10 : 0;
        minSize.height = me.maxHeight; // fill all the height
      } // Increase sizes here


      if (display) {
        ctx.font = labelFont;

        if (isHorizontal) {
          // Labels
          // Width of each line of legend boxes. Labels wrap onto multiple lines when there are too many to fit on one
          var lineWidths = me.lineWidths = [0];
          var totalHeight = me.legendItems.length ? fontSize + labelOpts.padding : 0;
          ctx.textAlign = 'left';
          ctx.textBaseline = 'top';
          helpers.each(me.legendItems, function (legendItem, i) {
            var boxWidth = getBoxWidth(labelOpts, fontSize);
            var width = boxWidth + fontSize / 2 + ctx.measureText(legendItem.text).width;

            if (lineWidths[lineWidths.length - 1] + width + labelOpts.padding >= me.width) {
              totalHeight += fontSize + labelOpts.padding;
              lineWidths[lineWidths.length] = me.left;
            } // Store the hitbox width and height here. Final position will be updated in `draw`


            hitboxes[i] = {
              left: 0,
              top: 0,
              width: width,
              height: fontSize
            };
            lineWidths[lineWidths.length - 1] += width + labelOpts.padding;
          });
          minSize.height += totalHeight;
        } else {
          var vPadding = labelOpts.padding;
          var columnWidths = me.columnWidths = [];
          var totalWidth = labelOpts.padding;
          var currentColWidth = 0;
          var currentColHeight = 0;
          var itemHeight = fontSize + vPadding;
          helpers.each(me.legendItems, function (legendItem, i) {
            var boxWidth = getBoxWidth(labelOpts, fontSize);
            var itemWidth = boxWidth + fontSize / 2 + ctx.measureText(legendItem.text).width; // If too tall, go to new column

            if (currentColHeight + itemHeight > minSize.height) {
              totalWidth += currentColWidth + labelOpts.padding;
              columnWidths.push(currentColWidth); // previous column width

              currentColWidth = 0;
              currentColHeight = 0;
            } // Get max width


            currentColWidth = Math.max(currentColWidth, itemWidth);
            currentColHeight += itemHeight; // Store the hitbox width and height here. Final position will be updated in `draw`

            hitboxes[i] = {
              left: 0,
              top: 0,
              width: itemWidth,
              height: fontSize
            };
          });
          totalWidth += currentColWidth;
          columnWidths.push(currentColWidth);
          minSize.width += totalWidth;
        }
      }

      me.width = minSize.width;
      me.height = minSize.height;
    },
    afterFit: noop,
    // Shared Methods
    isHorizontal: function isHorizontal() {
      return this.options.position === 'top' || this.options.position === 'bottom';
    },
    // Actually draw the legend on the canvas
    draw: function draw() {
      var me = this;
      var opts = me.options;
      var labelOpts = opts.labels;
      var globalDefault = defaults.global;
      var lineDefault = globalDefault.elements.line;
      var legendWidth = me.width;
      var lineWidths = me.lineWidths;

      if (opts.display) {
        var ctx = me.ctx;
        var valueOrDefault = helpers.valueOrDefault;
        var fontColor = valueOrDefault(labelOpts.fontColor, globalDefault.defaultFontColor);
        var fontSize = valueOrDefault(labelOpts.fontSize, globalDefault.defaultFontSize);
        var fontStyle = valueOrDefault(labelOpts.fontStyle, globalDefault.defaultFontStyle);
        var fontFamily = valueOrDefault(labelOpts.fontFamily, globalDefault.defaultFontFamily);
        var labelFont = helpers.fontString(fontSize, fontStyle, fontFamily);
        var cursor; // Canvas setup

        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.lineWidth = 0.5;
        ctx.strokeStyle = fontColor; // for strikethrough effect

        ctx.fillStyle = fontColor; // render in correct colour

        ctx.font = labelFont;
        var boxWidth = getBoxWidth(labelOpts, fontSize);
        var hitboxes = me.legendHitBoxes; // current position

        var drawLegendBox = function drawLegendBox(x, y, legendItem) {
          if (isNaN(boxWidth) || boxWidth <= 0) {
            return;
          } // Set the ctx for the box


          ctx.save();
          ctx.fillStyle = valueOrDefault(legendItem.fillStyle, globalDefault.defaultColor);
          ctx.lineCap = valueOrDefault(legendItem.lineCap, lineDefault.borderCapStyle);
          ctx.lineDashOffset = valueOrDefault(legendItem.lineDashOffset, lineDefault.borderDashOffset);
          ctx.lineJoin = valueOrDefault(legendItem.lineJoin, lineDefault.borderJoinStyle);
          ctx.lineWidth = valueOrDefault(legendItem.lineWidth, lineDefault.borderWidth);
          ctx.strokeStyle = valueOrDefault(legendItem.strokeStyle, globalDefault.defaultColor);
          var isLineWidthZero = valueOrDefault(legendItem.lineWidth, lineDefault.borderWidth) === 0;

          if (ctx.setLineDash) {
            // IE 9 and 10 do not support line dash
            ctx.setLineDash(valueOrDefault(legendItem.lineDash, lineDefault.borderDash));
          }

          if (opts.labels && opts.labels.usePointStyle) {
            // Recalculate x and y for drawPoint() because its expecting
            // x and y to be center of figure (instead of top left)
            var radius = fontSize * Math.SQRT2 / 2;
            var offSet = radius / Math.SQRT2;
            var centerX = x + offSet;
            var centerY = y + offSet; // Draw pointStyle as legend symbol

            helpers.canvas.drawPoint(ctx, legendItem.pointStyle, radius, centerX, centerY);
          } else {
            // Draw box as legend symbol
            if (!isLineWidthZero) {
              ctx.strokeRect(x, y, boxWidth, fontSize);
            }

            ctx.fillRect(x, y, boxWidth, fontSize);
          }

          ctx.restore();
        };

        var fillText = function fillText(x, y, legendItem, textWidth) {
          var halfFontSize = fontSize / 2;
          var xLeft = boxWidth + halfFontSize + x;
          var yMiddle = y + halfFontSize;
          ctx.fillText(legendItem.text, xLeft, yMiddle);

          if (legendItem.hidden) {
            // Strikethrough the text if hidden
            ctx.beginPath();
            ctx.lineWidth = 2;
            ctx.moveTo(xLeft, yMiddle);
            ctx.lineTo(xLeft + textWidth, yMiddle);
            ctx.stroke();
          }
        }; // Horizontal


        var isHorizontal = me.isHorizontal();

        if (isHorizontal) {
          cursor = {
            x: me.left + (legendWidth - lineWidths[0]) / 2,
            y: me.top + labelOpts.padding,
            line: 0
          };
        } else {
          cursor = {
            x: me.left + labelOpts.padding,
            y: me.top + labelOpts.padding,
            line: 0
          };
        }

        var itemHeight = fontSize + labelOpts.padding;
        helpers.each(me.legendItems, function (legendItem, i) {
          var textWidth = ctx.measureText(legendItem.text).width;
          var width = boxWidth + fontSize / 2 + textWidth;
          var x = cursor.x;
          var y = cursor.y;

          if (isHorizontal) {
            if (x + width >= legendWidth) {
              y = cursor.y += itemHeight;
              cursor.line++;
              x = cursor.x = me.left + (legendWidth - lineWidths[cursor.line]) / 2;
            }
          } else if (y + itemHeight > me.bottom) {
            x = cursor.x = x + me.columnWidths[cursor.line] + labelOpts.padding;
            y = cursor.y = me.top + labelOpts.padding;
            cursor.line++;
          }

          drawLegendBox(x, y, legendItem);
          hitboxes[i].left = x;
          hitboxes[i].top = y; // Fill the actual label

          fillText(x, y, legendItem, textWidth);

          if (isHorizontal) {
            cursor.x += width + labelOpts.padding;
          } else {
            cursor.y += itemHeight;
          }
        });
      }
    },

    /**
     * Handle an event
     * @private
     * @param {IEvent} event - The event to handle
     * @return {Boolean} true if a change occured
     */
    handleEvent: function handleEvent(e) {
      var me = this;
      var opts = me.options;
      var type = e.type === 'mouseup' ? 'click' : e.type;
      var changed = false;

      if (type === 'mousemove') {
        if (!opts.onHover) {
          return;
        }
      } else if (type === 'click') {
        if (!opts.onClick) {
          return;
        }
      } else {
        return;
      } // Chart event already has relative position in it


      var x = e.x;
      var y = e.y;

      if (x >= me.left && x <= me.right && y >= me.top && y <= me.bottom) {
        // See if we are touching one of the dataset boxes
        var lh = me.legendHitBoxes;

        for (var i = 0; i < lh.length; ++i) {
          var hitBox = lh[i];

          if (x >= hitBox.left && x <= hitBox.left + hitBox.width && y >= hitBox.top && y <= hitBox.top + hitBox.height) {
            // Touching an element
            if (type === 'click') {
              // use e.native for backwards compatibility
              opts.onClick.call(me, e.native, me.legendItems[i]);
              changed = true;
              break;
            } else if (type === 'mousemove') {
              // use e.native for backwards compatibility
              opts.onHover.call(me, e.native, me.legendItems[i]);
              changed = true;
              break;
            }
          }
        }
      }

      return changed;
    }
  });

  function createNewLegendAndAttach(chart, legendOpts) {
    var legend = new Chart.Legend({
      ctx: chart.ctx,
      options: legendOpts,
      chart: chart
    });
    layout.configure(chart, legend, legendOpts);
    layout.addBox(chart, legend);
    chart.legend = legend;
  }

  return {
    id: 'legend',
    beforeInit: function beforeInit(chart) {
      var legendOpts = chart.options.legend;

      if (legendOpts) {
        createNewLegendAndAttach(chart, legendOpts);
      }
    },
    beforeUpdate: function beforeUpdate(chart) {
      var legendOpts = chart.options.legend;
      var legend = chart.legend;

      if (legendOpts) {
        helpers.mergeIf(legendOpts, defaults.global.legend);

        if (legend) {
          layout.configure(chart, legend, legendOpts);
          legend.options = legendOpts;
        } else {
          createNewLegendAndAttach(chart, legendOpts);
        }
      } else if (legend) {
        layout.removeBox(chart, legend);
        delete chart.legend;
      }
    },
    afterEvent: function afterEvent(chart, e) {
      var legend = chart.legend;

      if (legend) {
        legend.handleEvent(e);
      }
    }
  };
};