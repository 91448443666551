'use strict';

var defaults = require('../core/core.defaults');

var elements = require('../elements/index');

var helpers = require('../helpers/index');

defaults._set('bar', {
  hover: {
    mode: 'label'
  },
  scales: {
    xAxes: [{
      type: 'category',
      // Specific to Bar Controller
      categoryPercentage: 0.8,
      barPercentage: 0.9,
      // offset settings
      offset: true,
      // grid line settings
      gridLines: {
        offsetGridLines: true
      }
    }],
    yAxes: [{
      type: 'linear'
    }]
  }
});

defaults._set('horizontalBar', {
  hover: {
    mode: 'index',
    axis: 'y'
  },
  scales: {
    xAxes: [{
      type: 'linear',
      position: 'bottom'
    }],
    yAxes: [{
      position: 'left',
      type: 'category',
      // Specific to Horizontal Bar Controller
      categoryPercentage: 0.8,
      barPercentage: 0.9,
      // offset settings
      offset: true,
      // grid line settings
      gridLines: {
        offsetGridLines: true
      }
    }]
  },
  elements: {
    rectangle: {
      borderSkipped: 'left'
    }
  },
  tooltips: {
    callbacks: {
      title: function title(item, data) {
        // Pick first xLabel for now
        var title = '';

        if (item.length > 0) {
          if (item[0].yLabel) {
            title = item[0].yLabel;
          } else if (data.labels.length > 0 && item[0].index < data.labels.length) {
            title = data.labels[item[0].index];
          }
        }

        return title;
      },
      label: function label(item, data) {
        var datasetLabel = data.datasets[item.datasetIndex].label || '';
        return datasetLabel + ': ' + item.xLabel;
      }
    },
    mode: 'index',
    axis: 'y'
  }
});

module.exports = function (Chart) {
  Chart.controllers.bar = Chart.DatasetController.extend({
    dataElementType: elements.Rectangle,
    initialize: function initialize() {
      var me = this;
      var meta;
      Chart.DatasetController.prototype.initialize.apply(me, arguments);
      meta = me.getMeta();
      meta.stack = me.getDataset().stack;
      meta.bar = true;
    },
    update: function update(reset) {
      var me = this;
      var rects = me.getMeta().data;
      var i, ilen;
      me._ruler = me.getRuler();

      for (i = 0, ilen = rects.length; i < ilen; ++i) {
        me.updateElement(rects[i], i, reset);
      }
    },
    updateElement: function updateElement(rectangle, index, reset) {
      var me = this;
      var chart = me.chart;
      var meta = me.getMeta();
      var dataset = me.getDataset();
      var custom = rectangle.custom || {};
      var rectangleOptions = chart.options.elements.rectangle;
      rectangle._xScale = me.getScaleForId(meta.xAxisID);
      rectangle._yScale = me.getScaleForId(meta.yAxisID);
      rectangle._datasetIndex = me.index;
      rectangle._index = index;
      rectangle._model = {
        datasetLabel: dataset.label,
        label: chart.data.labels[index],
        borderSkipped: custom.borderSkipped ? custom.borderSkipped : rectangleOptions.borderSkipped,
        backgroundColor: custom.backgroundColor ? custom.backgroundColor : helpers.valueAtIndexOrDefault(dataset.backgroundColor, index, rectangleOptions.backgroundColor),
        borderColor: custom.borderColor ? custom.borderColor : helpers.valueAtIndexOrDefault(dataset.borderColor, index, rectangleOptions.borderColor),
        borderWidth: custom.borderWidth ? custom.borderWidth : helpers.valueAtIndexOrDefault(dataset.borderWidth, index, rectangleOptions.borderWidth)
      };
      me.updateElementGeometry(rectangle, index, reset);
      rectangle.pivot();
    },

    /**
     * @private
     */
    updateElementGeometry: function updateElementGeometry(rectangle, index, reset) {
      var me = this;
      var model = rectangle._model;
      var vscale = me.getValueScale();
      var base = vscale.getBasePixel();
      var horizontal = vscale.isHorizontal();
      var ruler = me._ruler || me.getRuler();
      var vpixels = me.calculateBarValuePixels(me.index, index);
      var ipixels = me.calculateBarIndexPixels(me.index, index, ruler);
      model.horizontal = horizontal;
      model.base = reset ? base : vpixels.base;
      model.x = horizontal ? reset ? base : vpixels.head : ipixels.center;
      model.y = horizontal ? ipixels.center : reset ? base : vpixels.head;
      model.height = horizontal ? ipixels.size : undefined;
      model.width = horizontal ? undefined : ipixels.size;
    },

    /**
     * @private
     */
    getValueScaleId: function getValueScaleId() {
      return this.getMeta().yAxisID;
    },

    /**
     * @private
     */
    getIndexScaleId: function getIndexScaleId() {
      return this.getMeta().xAxisID;
    },

    /**
     * @private
     */
    getValueScale: function getValueScale() {
      return this.getScaleForId(this.getValueScaleId());
    },

    /**
     * @private
     */
    getIndexScale: function getIndexScale() {
      return this.getScaleForId(this.getIndexScaleId());
    },

    /**
     * Returns the effective number of stacks based on groups and bar visibility.
     * @private
     */
    getStackCount: function getStackCount(last) {
      var me = this;
      var chart = me.chart;
      var scale = me.getIndexScale();
      var stacked = scale.options.stacked;
      var ilen = last === undefined ? chart.data.datasets.length : last + 1;
      var stacks = [];
      var i, meta;

      for (i = 0; i < ilen; ++i) {
        meta = chart.getDatasetMeta(i);

        if (meta.bar && chart.isDatasetVisible(i) && (stacked === false || stacked === true && stacks.indexOf(meta.stack) === -1 || stacked === undefined && (meta.stack === undefined || stacks.indexOf(meta.stack) === -1))) {
          stacks.push(meta.stack);
        }
      }

      return stacks.length;
    },

    /**
     * Returns the stack index for the given dataset based on groups and bar visibility.
     * @private
     */
    getStackIndex: function getStackIndex(datasetIndex) {
      return this.getStackCount(datasetIndex) - 1;
    },

    /**
     * @private
     */
    getRuler: function getRuler() {
      var me = this;
      var scale = me.getIndexScale();
      var stackCount = me.getStackCount();
      var datasetIndex = me.index;
      var pixels = [];
      var isHorizontal = scale.isHorizontal();
      var start = isHorizontal ? scale.left : scale.top;
      var end = start + (isHorizontal ? scale.width : scale.height);
      var i, ilen;

      for (i = 0, ilen = me.getMeta().data.length; i < ilen; ++i) {
        pixels.push(scale.getPixelForValue(null, i, datasetIndex));
      }

      return {
        pixels: pixels,
        start: start,
        end: end,
        stackCount: stackCount,
        scale: scale
      };
    },

    /**
     * Note: pixel values are not clamped to the scale area.
     * @private
     */
    calculateBarValuePixels: function calculateBarValuePixels(datasetIndex, index) {
      var me = this;
      var chart = me.chart;
      var meta = me.getMeta();
      var scale = me.getValueScale();
      var datasets = chart.data.datasets;
      var value = scale.getRightValue(datasets[datasetIndex].data[index]);
      var stacked = scale.options.stacked;
      var stack = meta.stack;
      var start = 0;
      var i, imeta, ivalue, base, head, size;

      if (stacked || stacked === undefined && stack !== undefined) {
        for (i = 0; i < datasetIndex; ++i) {
          imeta = chart.getDatasetMeta(i);

          if (imeta.bar && imeta.stack === stack && imeta.controller.getValueScaleId() === scale.id && chart.isDatasetVisible(i)) {
            ivalue = scale.getRightValue(datasets[i].data[index]);

            if (value < 0 && ivalue < 0 || value >= 0 && ivalue > 0) {
              start += ivalue;
            }
          }
        }
      }

      base = scale.getPixelForValue(start);
      head = scale.getPixelForValue(start + value);
      size = (head - base) / 2;
      return {
        size: size,
        base: base,
        head: head,
        center: head + size / 2
      };
    },

    /**
     * @private
     */
    calculateBarIndexPixels: function calculateBarIndexPixels(datasetIndex, index, ruler) {
      var me = this;
      var options = ruler.scale.options;
      var stackIndex = me.getStackIndex(datasetIndex);
      var pixels = ruler.pixels;
      var base = pixels[index];
      var length = pixels.length;
      var start = ruler.start;
      var end = ruler.end;
      var leftSampleSize, rightSampleSize, leftCategorySize, rightCategorySize, fullBarSize, size;

      if (length === 1) {
        leftSampleSize = base > start ? base - start : end - base;
        rightSampleSize = base < end ? end - base : base - start;
      } else {
        if (index > 0) {
          leftSampleSize = (base - pixels[index - 1]) / 2;

          if (index === length - 1) {
            rightSampleSize = leftSampleSize;
          }
        }

        if (index < length - 1) {
          rightSampleSize = (pixels[index + 1] - base) / 2;

          if (index === 0) {
            leftSampleSize = rightSampleSize;
          }
        }
      }

      leftCategorySize = leftSampleSize * options.categoryPercentage;
      rightCategorySize = rightSampleSize * options.categoryPercentage;
      fullBarSize = (leftCategorySize + rightCategorySize) / ruler.stackCount;
      size = fullBarSize * options.barPercentage;
      size = Math.min(helpers.valueOrDefault(options.barThickness, size), helpers.valueOrDefault(options.maxBarThickness, Infinity));
      base -= leftCategorySize;
      base += fullBarSize * stackIndex;
      base += (fullBarSize - size) / 2;
      return {
        size: size,
        base: base,
        head: base + size,
        center: base + size / 2
      };
    },
    draw: function draw() {
      var me = this;
      var chart = me.chart;
      var scale = me.getValueScale();
      var rects = me.getMeta().data;
      var dataset = me.getDataset();
      var ilen = rects.length;
      var i = 0;
      helpers.canvas.clipArea(chart.ctx, chart.chartArea);

      for (; i < ilen; ++i) {
        if (!isNaN(scale.getRightValue(dataset.data[i]))) {
          rects[i].draw();
        }
      }

      helpers.canvas.unclipArea(chart.ctx);
    },
    setHoverStyle: function setHoverStyle(rectangle) {
      var dataset = this.chart.data.datasets[rectangle._datasetIndex];
      var index = rectangle._index;
      var custom = rectangle.custom || {};
      var model = rectangle._model;
      model.backgroundColor = custom.hoverBackgroundColor ? custom.hoverBackgroundColor : helpers.valueAtIndexOrDefault(dataset.hoverBackgroundColor, index, helpers.getHoverColor(model.backgroundColor));
      model.borderColor = custom.hoverBorderColor ? custom.hoverBorderColor : helpers.valueAtIndexOrDefault(dataset.hoverBorderColor, index, helpers.getHoverColor(model.borderColor));
      model.borderWidth = custom.hoverBorderWidth ? custom.hoverBorderWidth : helpers.valueAtIndexOrDefault(dataset.hoverBorderWidth, index, model.borderWidth);
    },
    removeHoverStyle: function removeHoverStyle(rectangle) {
      var dataset = this.chart.data.datasets[rectangle._datasetIndex];
      var index = rectangle._index;
      var custom = rectangle.custom || {};
      var model = rectangle._model;
      var rectangleElementOptions = this.chart.options.elements.rectangle;
      model.backgroundColor = custom.backgroundColor ? custom.backgroundColor : helpers.valueAtIndexOrDefault(dataset.backgroundColor, index, rectangleElementOptions.backgroundColor);
      model.borderColor = custom.borderColor ? custom.borderColor : helpers.valueAtIndexOrDefault(dataset.borderColor, index, rectangleElementOptions.borderColor);
      model.borderWidth = custom.borderWidth ? custom.borderWidth : helpers.valueAtIndexOrDefault(dataset.borderWidth, index, rectangleElementOptions.borderWidth);
    }
  });
  Chart.controllers.horizontalBar = Chart.controllers.bar.extend({
    /**
     * @private
     */
    getValueScaleId: function getValueScaleId() {
      return this.getMeta().xAxisID;
    },

    /**
     * @private
     */
    getIndexScaleId: function getIndexScaleId() {
      return this.getMeta().yAxisID;
    }
  });
};