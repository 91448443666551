/*** IMPORTS FROM imports-loader ***/
(function() {

(function (root, factory) {
	if (typeof define === 'function' && define.amd) {
		define(['chartist'], function (chartist) {
			return (root.returnExportsGlobal = factory(chartist));
		});
	}
	else if (typeof exports === 'object')
		module.exports = factory(require('chartist'));
	else
		root['Chartist.plugins.responsiveLabels'] = factory(root.Chartist);
}(this, function (Chartist) {

	var defaultOptions = {
		minLabelWidth: 55,
		labelPadding: 0,
		emptyLabelValue: ''
	};

	Chartist.plugins = Chartist.plugins || {};

	Chartist.plugins.responsiveLabels = function (options) {
		options = Chartist.extend({}, defaultOptions, options);
		return function responsiveLabels(chart) {
			var
				labelContainerWidth = undefined,
				newLabels = [],
				chartScaleOptions = {
					labelContainerWidth: undefined,
					labelWidth: options.minLabelWidth, //setting as minWidth of the label
					labelPadding: options.labelPadding,
					emptyLabelValue: options.emptyLabelValue,
					labels: chart.options.originalLabels
				}
			;

			chart.on('draw', function (data) {
				if (data.type === "label" && data.axis.units.pos === "x") {
					// if (data.axis.options.showLabel) {
						labelContainerWidth = data.axis.axisLength;
						if (newLabels.length === 0) {
							chartScaleOptions.labelContainerWidth = labelContainerWidth;
							newLabels = getResponsiveGraphLabels(chartScaleOptions);
							data.text = newLabels.shift();
							data.element.replace(getNewLabelElement(data, "ct-horizontal ct-end"));
						}
						else {
							data.text = newLabels.shift();
							data.element.replace(getNewLabelElement(data, "ct-horizontal ct-end"));
						}
					// }
				}

				if (data.type === "label" && data.axis.units.pos === "y") {
					data.element.replace(getNewLabelElement(data, "ct-vertical ct-start", 35));
				}
			});

			function getNewLabelElement(data, classes, width) {
				var spanHTML = '<span class="ct-label ' + classes + '" style="width: ' + data.width + 'px; height: ' + data.height + 'px">' + data.text + '</span>';
				var objectAttributes = {
					x: data.x,
					y: data.y,
					width: width ? width : data.width,
					height: data.height
				};
				return Chartist.Svg().foreignObject(spanHTML, objectAttributes);
			}

			function getResponsiveGraphLabels(options) {
				var primeFactor = {
					calculate: function calculate(num) {
						var result =
							arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];
						var repeat =
							arguments.length <= 2 || arguments[2] === undefined ? true : arguments[2];

						var root = Math.sqrt(num);
						var x = 2;

						if (num % x) {
							x = 3;
							while (num % x && x < root) {
								x += 2;
							}
						}

						x = x <= root ? x : num;

						if (!repeat) {
							var index = result.indexOf(x);
							if (index < 0) result.push(x);
						} else result.push(x);

						return x === num ? result : this.calculate(num / x, result, repeat);
					},
					getFrequency: function getFrequency(num) {
						var factors = this.calculate(num);
						var count = {};
						var result = [];

						for (var i in factors) {
							if (count.hasOwnProperty(factors[i])) {
								count[factors[i]] += 1;
							} else count[factors[i]] = 1;
						}

						for (var key in count) {
							if ({}.hasOwnProperty.call(count, key)) {
								var obj = {
									factor: Number(key),
									times: count[key]
								};
								result.push(obj);
							}
						}

						return result;
					},
					getUniqueFactors: function getUniqueFactors(num) {
						return this.calculate(num, [], false);
					},
					isPrime: function isPrime(num) {
						var ok = true;
						for (var i = 2; i <= Math.floor(Math.sqrt(num)); i++) {
							if (num % i === 0) {
								ok = false;
								break;
							}
						}

						return ok;
					}
				};

				var responsiveGraphLabels = {
					scale: function scale(options) {
						this.labels = options.labels;
						this.labelWidth = options.labelWidth;
						this.labelPadding = options.labelPadding;
						this.labelContainerWidth = options.labelContainerWidth;
						this.emptyLabelValue = options.emptyLabelValue;

						if (this.canShowAllLabels()) {
							return this.labels;
						}

						return this.distributeLabels();
					},
					distributeLabels: function distributeLabels() {
						if (
							this.labels.length % 2 === 0 &&
							primeFactor.isPrime(this.labels.length - 1)
						) {
							return this.bestPossibleDistribution();
						}

						return this.evenDistribution();
					},
					canShowAllLabels: function canShowAllLabels() {
						return this.labels.length < this.labelContainerCapacity();
					},
					labelContainerCapacity: function labelContainerCapacity() {
						return Math.floor(
							this.labelContainerWidth / (this.labelWidth + this.labelPadding)
						);
					},
					bestPossibleDistribution: function bestPossibleDistribution() {
						var _this = this;

						var weightedLimit = this.labelContainerCapacity() - 1;
						var result = [];

						for (var i = 0; i < weightedLimit; i += 1) {
							result.push(this.labels[this.arrayStride(this.labels, i, weightedLimit)]);
						}

						result.push(this.labels[this.labels.length - 1]);

						return this.labels.map(function (element) {
							if (element === result.slice(0, 1).pop()) {
								return result.shift();
							}
							return _this.emptyLabelValue;
						});
					},
					evenDistribution: function evenDistribution() {
						var _this2 = this;

						var bestProgression = this.findBestProgression();
						var markedIndexes = (0, range)(
							0,
							this.labels.length,
							bestProgression.factor
						);

						return this.labels.map(function (element, index) {
							if (markedIndexes.indexOf(index) !== -1) {
								return element;
							}

							return _this2.emptyLabelValue;
						});
					},
					generateProgressionOptions: function generateProgressionOptions() {
						var _this3 = this;

						var factors = primeFactor.getUniqueFactors(this.labels.length - 1);
						return factors.map(function (value) {
							var count = (_this3.labels.length - 1) / value + 1;
							return {
								factor: value,
								count: count
							};
						});
					},
					findBestProgression: function findBestProgression() {
						var limit = this.labelContainerCapacity();
						var options = this.generateProgressionOptions();
						return options.reduce(function (prev, curr) {
							return Math.abs(curr.count - limit) < Math.abs(prev.count - limit)
								? curr
								: prev;
						});
					},
					arrayStride: function arrayStride(labels, index, limit) {
						return Math.floor(index * (labels.length / limit));
					}
				};

				function range(a, b, step) {
					if (arguments.length === 1) {
						b = a;
						a = 0;
					}

					step = step || 1;

					var x,
						r = [];

					for (x = a; (b - x) * step > 0; x += step) {
						r.push(x);
					}

					return r;
				}

				return _.clone(responsiveGraphLabels.scale(options));
			}
		}
	};

	return Chartist.plugins.responsiveLabels;
}));
}.call(window));