/*** IMPORTS FROM imports-loader ***/
(function() {

(function (root, factory) {
	if (typeof define === 'function' && define.amd) {
		define([ 'chartist' ], function (chartist) {
			return (root.returnExportsGlobal = factory(chartist));
		});
	}
	else if (typeof exports === 'object')
		module.exports = factory(require('chartist'));
	else
		root['Chartist.plugins.point'] = factory(root.Chartist);
}(this, function (Chartist) {

	var defaultOptions = {
		shape: "circle",
		circleShapeOptions: {
			radius: 6,
			className: "ct-custom-point",
			enableAnimations: false,
			fillColor: "white"
		},
		squareShapeOptions: {

		}
	};

	Chartist.plugins = Chartist.plugins || {};

	Chartist.plugins.point = function (options) {
		options = Chartist.extend({}, defaultOptions, options);
		var numberOfDataPoints = undefined;
		return function point(chart) {
			var circlePoint;
			if (chart instanceof Chartist.Line) {
				numberOfDataPoints = chart.data.labels.length;
				chart.on('draw', function(data) {
					if (data.type === 'point') {
						if (options.shape === "circle") {
							if ((data.axisX.stepLength / numberOfDataPoints) < (options.circleShapeOptions.radius * 2)) {
								options.circleShapeOptions.radius = 4
							}
							//animate
							if (options.circleShapeOptions.enableAnimations) {
								data.element.animate({
									cy: {
										begin: 0,
										dur: 500,
										from: data.axisY.chartRect.y1,
										to: data.y,
										easing: Chartist.Svg.Easing.easeInOutSine
									}
								});
							}
							data.element.replace(getCircleShape(data, options));
							data.group.attr({ style: "fill: " + options.circleShapeOptions.fillColor + "; !important" });
						}
					}
				});
			}
		}
	};

	function getCircleShape(data, options) {
		return Chartist.Svg("circle", {
			cx: data.x,
			cy: data.y,
			r: options.circleShapeOptions.radius,
			stroke: data.series.color,
			'ct:value': data.value.y,
			'ct:meta': data.meta,
		}, options.circleShapeOptions.className );
	}

	function getSquareShape(data, options) {

	}

	return Chartist.plugins.point;
}));

}.call(window));