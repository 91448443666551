/*** IMPORTS FROM imports-loader ***/
(function() {

/**
 * Chartist.js plugin to display a data label on top of the points in a line chart.
 *
 */
/* global Chartist */
(function (root, factory) {
	if (typeof define === 'function' && define.amd) {
		define([ 'chartist' ], function (chartist) {
			return (root.returnExportsGlobal = factory(chartist));
		});
	}
	else if (typeof exports === 'object')
		module.exports = factory(require('chartist'));
	else
		root['Chartist.plugins.seriesSelection'] = factory(root.Chartist);
}(this, function(Chartist) {
    'use strict';
  
    var defaultOptions = {
      labelClass: 'ct-pointlabel',
      labelOffset: {
        x: 0,
        y: -10
      },
      fontSize: '11px',
      align: 'center',
      labelInterpolationFnc: Chartist.noop
    };
  
    var labelPositionCalculation = {
      point: function(data) {
        return {
          x: data.x,
          y: data.y
        };
      },
      bar: {
        left: function(data) {
          return {
            x: data.x1,
            y: data.y1
          };
        },
          center: function (data) {
          return {
            x: data.x1,
            y: data.y2
          };
        },
        right: function(data) {
          return {
            x: data.x2,
            y: data.y1
          };
        }
      }
    };
  
    Chartist.plugins = Chartist.plugins || {};
    Chartist.plugins.ctPointLabels = function(options) {
  
      options = Chartist.extend({}, defaultOptions, options);
  
      function addLabel(position, data) {
        // if x and y exist concat them otherwise output only the existing value
        var value = data.value.x !== undefined && data.value.y ?
          (data.value.x + ', ' + data.value.y) :
          data.value.y || data.value.x;
  
        data.group.elem('text', {
          x: position.x + options.labelOffset.x,
          y: position.y + options.labelOffset.y,
        }, options.labelClass).text(options.labelInterpolationFnc(value));
      }
  
        
      return function ctPointLabels(chart) {
        if (chart instanceof Chartist.Line) {
          chart.on('draw', function (data) {
            var positonCalculator = labelPositionCalculation[data.type] && labelPositionCalculation[data.type][options.align] || labelPositionCalculation[data.type];
            if (positonCalculator) {

              // if large circles according to chartist-plugins-point.js
              if (!((data.axisX.stepLength / chart.data.labels.length) < 12)) {
                options.labelOffset.y = -12 
              }
              
              addLabel(positonCalculator(data), data);
            }
          });
        }
        if (chart instanceof Chartist.Bar) {
            chart.on('draw', function(data) {
              var positonCalculator = labelPositionCalculation[data.type] && labelPositionCalculation[data.type][options.align] || labelPositionCalculation[data.type];

              options.labelOffset.y = -6
              
              if (positonCalculator) {
                addLabel(positonCalculator(data), data);
              }
            });
        }
        
      };
    };
  
    return Chartist.plugins.ctPointLabels;
  }));
}.call(window));