import '@babel/polyfill'
import './vendorStyles'

import 'less/dist/less.min.js'
import 'es5-shim/es5-shim.js'

const $ = require('jquery/dist/jquery.js')

window.$ = $
window.jQuery = $

require('./scripts/jReject/js/jquery.reject.js')
require('icheck/icheck.js')
require('./scripts/browser-check-before.js')

angular = require('angular')

require('bootstrap/dist/js/bootstrap.js')
require('./vendorRequire')
require('./assets/plugins/offline/offline.js')
