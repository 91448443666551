'use strict';

var defaults = require('../core/core.defaults');

var Element = require('../core/core.element');

var helpers = require('../helpers/index');

defaults._set('global', {
  title: {
    display: false,
    fontStyle: 'bold',
    fullWidth: true,
    lineHeight: 1.2,
    padding: 10,
    position: 'top',
    text: '',
    weight: 2000 // by default greater than legend (1000) to be above

  }
});

module.exports = function (Chart) {
  var layout = Chart.layoutService;
  var noop = helpers.noop;
  Chart.Title = Element.extend({
    initialize: function initialize(config) {
      var me = this;
      helpers.extend(me, config); // Contains hit boxes for each dataset (in dataset order)

      me.legendHitBoxes = [];
    },
    // These methods are ordered by lifecycle. Utilities then follow.
    beforeUpdate: noop,
    update: function update(maxWidth, maxHeight, margins) {
      var me = this; // Update Lifecycle - Probably don't want to ever extend or overwrite this function ;)

      me.beforeUpdate(); // Absorb the master measurements

      me.maxWidth = maxWidth;
      me.maxHeight = maxHeight;
      me.margins = margins; // Dimensions

      me.beforeSetDimensions();
      me.setDimensions();
      me.afterSetDimensions(); // Labels

      me.beforeBuildLabels();
      me.buildLabels();
      me.afterBuildLabels(); // Fit

      me.beforeFit();
      me.fit();
      me.afterFit(); //

      me.afterUpdate();
      return me.minSize;
    },
    afterUpdate: noop,
    //
    beforeSetDimensions: noop,
    setDimensions: function setDimensions() {
      var me = this; // Set the unconstrained dimension before label rotation

      if (me.isHorizontal()) {
        // Reset position before calculating rotation
        me.width = me.maxWidth;
        me.left = 0;
        me.right = me.width;
      } else {
        me.height = me.maxHeight; // Reset position before calculating rotation

        me.top = 0;
        me.bottom = me.height;
      } // Reset padding


      me.paddingLeft = 0;
      me.paddingTop = 0;
      me.paddingRight = 0;
      me.paddingBottom = 0; // Reset minSize

      me.minSize = {
        width: 0,
        height: 0
      };
    },
    afterSetDimensions: noop,
    //
    beforeBuildLabels: noop,
    buildLabels: noop,
    afterBuildLabels: noop,
    //
    beforeFit: noop,
    fit: function fit() {
      var me = this;
      var valueOrDefault = helpers.valueOrDefault;
      var opts = me.options;
      var display = opts.display;
      var fontSize = valueOrDefault(opts.fontSize, defaults.global.defaultFontSize);
      var minSize = me.minSize;
      var lineCount = helpers.isArray(opts.text) ? opts.text.length : 1;
      var lineHeight = helpers.options.toLineHeight(opts.lineHeight, fontSize);
      var textSize = display ? lineCount * lineHeight + opts.padding * 2 : 0;

      if (me.isHorizontal()) {
        minSize.width = me.maxWidth; // fill all the width

        minSize.height = textSize;
      } else {
        minSize.width = textSize;
        minSize.height = me.maxHeight; // fill all the height
      }

      me.width = minSize.width;
      me.height = minSize.height;
    },
    afterFit: noop,
    // Shared Methods
    isHorizontal: function isHorizontal() {
      var pos = this.options.position;
      return pos === 'top' || pos === 'bottom';
    },
    // Actually draw the title block on the canvas
    draw: function draw() {
      var me = this;
      var ctx = me.ctx;
      var valueOrDefault = helpers.valueOrDefault;
      var opts = me.options;
      var globalDefaults = defaults.global;

      if (opts.display) {
        var fontSize = valueOrDefault(opts.fontSize, globalDefaults.defaultFontSize);
        var fontStyle = valueOrDefault(opts.fontStyle, globalDefaults.defaultFontStyle);
        var fontFamily = valueOrDefault(opts.fontFamily, globalDefaults.defaultFontFamily);
        var titleFont = helpers.fontString(fontSize, fontStyle, fontFamily);
        var lineHeight = helpers.options.toLineHeight(opts.lineHeight, fontSize);
        var offset = lineHeight / 2 + opts.padding;
        var rotation = 0;
        var top = me.top;
        var left = me.left;
        var bottom = me.bottom;
        var right = me.right;
        var maxWidth, titleX, titleY;
        ctx.fillStyle = valueOrDefault(opts.fontColor, globalDefaults.defaultFontColor); // render in correct colour

        ctx.font = titleFont; // Horizontal

        if (me.isHorizontal()) {
          titleX = left + (right - left) / 2; // midpoint of the width

          titleY = top + offset;
          maxWidth = right - left;
        } else {
          titleX = opts.position === 'left' ? left + offset : right - offset;
          titleY = top + (bottom - top) / 2;
          maxWidth = bottom - top;
          rotation = Math.PI * (opts.position === 'left' ? -0.5 : 0.5);
        }

        ctx.save();
        ctx.translate(titleX, titleY);
        ctx.rotate(rotation);
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        var text = opts.text;

        if (helpers.isArray(text)) {
          var y = 0;

          for (var i = 0; i < text.length; ++i) {
            ctx.fillText(text[i], 0, y, maxWidth);
            y += lineHeight;
          }
        } else {
          ctx.fillText(text, 0, 0, maxWidth);
        }

        ctx.restore();
      }
    }
  });

  function createNewTitleBlockAndAttach(chart, titleOpts) {
    var title = new Chart.Title({
      ctx: chart.ctx,
      options: titleOpts,
      chart: chart
    });
    layout.configure(chart, title, titleOpts);
    layout.addBox(chart, title);
    chart.titleBlock = title;
  }

  return {
    id: 'title',
    beforeInit: function beforeInit(chart) {
      var titleOpts = chart.options.title;

      if (titleOpts) {
        createNewTitleBlockAndAttach(chart, titleOpts);
      }
    },
    beforeUpdate: function beforeUpdate(chart) {
      var titleOpts = chart.options.title;
      var titleBlock = chart.titleBlock;

      if (titleOpts) {
        helpers.mergeIf(titleOpts, defaults.global.title);

        if (titleBlock) {
          layout.configure(chart, titleBlock, titleOpts);
          titleBlock.options = titleOpts;
        } else {
          createNewTitleBlockAndAttach(chart, titleOpts);
        }
      } else if (titleBlock) {
        Chart.layoutService.removeBox(chart, titleBlock);
        delete chart.titleBlock;
      }
    }
  };
};